import { defineStore } from 'pinia';

export const useGeneralLedgerStore = defineStore('generalLedger', {
    state: () => ({
        reconciliationData: {},
        journalEntriesData: {},
        glSetupData: {},
        chartAccountsData: {},
        selectedTab: null,
        chargeCodeDetails: null,
        fiscalPeriodStart: '',
        fiscalPeriodEnd: '',
        glAccountDateFilters: {},
        investorOption: null,
    }),

    actions: {
        setGlSetupData(payload) {
            this.glSetupData = payload;
        },
        setChartAccountsData(payload) {
            this.chartAccountsData = payload;
        },
        storeSelectedTabComponentName(payload) {
            this.selectedTab = payload;
        },
        setJournalEntriesData(payload) {
            this.journalEntriesData = payload;
        },
        storeReconciliationData(payload) {
            this.reconciliationData = payload;
        },
        setChargeCodeDetails(payload) {
            this.chargeCodeDetails = payload;
        },
        setFiscalStart(payload) {
            this.fiscalPeriodStart = payload;
        },
        setFiscalEnd(payload) {
            this.fiscalPeriodEnd = payload;
        },
        setGlAccountDateFilters(payload) {
            this.glAccountDateFilters = payload;
        },
        setInvestorOption(payload) {
            this.investorOption = payload;
        },
        clearFiscalPeriod() {
            this.fiscalPeriodStart = '';
            this.fiscalPeriodEnd = '';
        },
    },
});
