export default {
    dataConsumingSystem: {
        getSchedules: ['POST', '/data-consuming-system/get-schedulers'],
        getOverallStats: ['POST', '/data-consuming-system/over-all-stats'],
        getJobs: ['GET', '/data-consuming-system/jobs/{requestId}/{page}/{limit}'],
        getJobStats: ['GET', '/data-consuming-system/job-stats/{jobTrasactionId}'],
        getTasks: ['GET', '/data-consuming-system/tasks/{jobTrasactionId}'],
        getTaskStatus: ['GET', '/data-consuming-system/task-status'],
        getList: ['POST', '/data-consuming-system/jobs'],
        getPartners: ['GET', '/data-consuming-system/partners'],
        getCommunities: ['GET', '/data-consuming-system/communities'],
        getCommunitiesByPartner: ['GET', '/data-consuming-system/partner-communities/{partnerId}'],
        saveSchedule: ['POST', '/cloud-watch/schedule'],
        getSchedule: ['GET', '/data-consuming-system/schedule/{scheduleId}'],
        getScheduleDetails: ['GET', '/data-consuming-system/schedule-details/{requestId}'],
        getJobDetails: ['GET', '/data-consuming-system/job-details/{jobTrasactionId}'],
        getEventDetails: ['GET', '/data-consuming-system/event-details/{messageId}'],
        updateSchedule: ['PUT', '/cloud-watch/schedule'],
        updateScheduleStatus: ['PATCH', '/cloud-watch/schedule'],
        deleteSchedule: ['DELETE', '/cloud-watch/schedule'],
        getScheduleData: ['GET', '/cloud-watch/schedule/{scheduleId}'],
        getCDCEvent: ['GET', '/data-consuming-system/cdc-event/{cdcId}'],
        getUnifiedMessage: ['GET', '/data-consuming-system/unified-event/{unifiedId}'],
        getCategories: ['GET', '/data-consuming-system/categories'],
    },
    modificationEvents: {
        getList: ['POST', '/data-consuming-system/modification-events'],
        getTableNames: ['GET', '/data-consuming-system/cdc-table-names'],
    },
    unifiedMessage: {
        getList: ['POST', '/data-consuming-system/unified-events'],
        getEntityNames: ['GET', '/data-consuming-system/unified-entity-names'],
    },
    permissions: '/my/permissions',
};
