import 'core-js';
import 'form-request-submit-polyfill';
import { createApp } from 'vue';
import { configureCompat } from '@vue/compat';

configureCompat({
    MODE: 3,

    INSTANCE_CHILDREN: true,
    WATCH_ARRAY: true,
    ATTR_FALSE_VALUE: true,
    ATTR_ENUMERATED_COERCION: true,
    COMPONENT_V_MODEL: true,
});

import { i18n } from './i18n';
import VueDOMPurifyHTML from 'vue-dompurify-html';

import App from './App.vue';
// Vue router setup
import router from './router';
// Vuex store instance
import vuexStore from './store';
// Pinia store instance
import piniaStore from './stores';
// tailwind setup
import './assets/styles.css';

// configure amplify
import { Amplify } from 'aws-amplify';
import aws_config from './aws_config';
Amplify.configure(aws_config);

// clockwork configuration
import { VueClockwork } from '@primitivesocial/clockwork';
import AuthDataProvider from '@/api/auth';
import PermissionsDataProvider from '@/api/permissions';
import CmsDataProvider from '@/api/cms';
import DHDataProvider from '@/api/digital_human'; // photosApi
import DHPhotosDataProvider from '@/api/digital_human/photosApi'; // photosApi
import SreqDataProvider from '@/api/sreq';
import ChatDataProvider from '@/api/chat';
import RaaDataProvider from '@/api/raa';
import FilemanagerDataProvider from '@/api/filemanager';
import MsgsDataProvider from '@/api/msgs';
import AttachmentsDataProvider from '@/api/attachments';
import AmntDataProvider from '@/api/amenities';
import LocationsDataProvider from '@/api/locations';
import BlueMoonDataProvider from '@/api/mvp_blue_moon';
import CbrDataProvider from '@/api/cbr';
import LeasingDataProvider from '@/api/leasing';
import RiDataProvider from '@/api/ri';
import IotDataProvider from '@/api/iot';
import ContactDataProvider from '@/api/contact';
import ReportDataProvider from '@/api/report';
import PartnerDataProvider from '@/api/partners';
import CalendarDataProvider from '@/api/calendar';
import GuestCardDataProvider from '@/api/guest_cards';
import AccountsDataProvider from '@/api/accounting';
import IntegrationsServiceDataProvider from '@/api/integrations';
import NotificationsDataProvider from '@/api/notifications';
import EmailbotDataProvider from '@/api/emailbot';
import EmailbotNoAuthDataProvider from '@/api/emailbot/noAuth';
import IntegrationsPartnersDataProvider from '@/api/integration_partner';
import DataConsumingSystemDataProvider from '@/api/data_consuming_system';
import EncsDataProvider from '@/api/encs';
import SearchDataProvider from '@/api/search';

const app = createApp(App);

app.use(piniaStore);
app.use(vuexStore);
app.use(router);
app.use(i18n);
app.use(VueDOMPurifyHTML, {
    default: {
        ALLOWED_TAGS: ['div', 'p', 'span', 'a', 'b', 'i', 'br', 'th', 'li', 'button', '#text'],
        ALLOWED_ATTR: ['class'],
    },
});

app.use(VueClockwork);

// Auth API data provider
app.use(AuthDataProvider, { name: 'authDataProvider' });

// Permission service data provider
app.use(PermissionsDataProvider, { name: 'permissionsDataProvider' });

// Cms API data provider
app.use(CmsDataProvider, { name: 'cmsDataProvider' });
// Digital Human API data provider
app.use(DHDataProvider, { name: 'dhDataProvider' });
// Digital Human Photos API data provider
app.use(DHPhotosDataProvider, { name: 'dhphotosDataProvider' });

// Service requests API data provider
app.use(SreqDataProvider, { name: 'sreqDataProvider' });

// Chat API data provider
app.use(ChatDataProvider, { name: 'chatDataProvider' });

// Resident auth app proxy data provider
app.use(RaaDataProvider, { name: 'raaDataProvider' });

// File manager data provider
app.use(FilemanagerDataProvider, { name: 'filemanagerDataProvider' });

// Messaging service data provider
app.use(MsgsDataProvider, { name: 'msgsDataProvider' });

// Attachments service data provider
app.use(AttachmentsDataProvider, { name: 'attachmentsDataProvider' });

// Amenities Data provider
app.use(AmntDataProvider, { name: 'amntDataProvider' });

// Location service data provider
app.use(LocationsDataProvider, { name: 'locationsDataProvider' });

// Property management service data provider
app.use(CbrDataProvider, { name: 'cbrDataProvider' });

// Leasing API data provider
app.use(LeasingDataProvider, { name: 'leasingDataProvider' });

// Accounting service data provider
app.use(AccountsDataProvider, { name: 'accountsDataProvider' });

// MVP Lease Service data provider
app.use(BlueMoonDataProvider, { name: 'blueMoonServiceDataProvider' });

// Guest Card data provider
app.use(GuestCardDataProvider, { name: 'guestCardDataProvider' });

// Rentable items service data provider
app.use(RiDataProvider, { name: 'riDataProvider' });

// IoT service data provider
app.use(IotDataProvider, { name: 'iotDataProvider' });

// Report API data provider
app.use(ReportDataProvider, { name: 'reportDataProvider' });

// Rentable items service data provider
app.use(ContactDataProvider, { name: 'contactDataProvider' });

// Partner data provider
app.use(PartnerDataProvider, { name: 'partnerDataProvider' });

// Calendar API data provider
app.use(CalendarDataProvider, { name: 'calendarDataProvider' });

// Integrations API data provider
app.use(IntegrationsServiceDataProvider, { name: 'integrationsDataProvider' });

// Search API data provider
app.use(SearchDataProvider, { name: 'searchDataProvider' });

// Notification service data provider
app.use(NotificationsDataProvider, { name: 'notificationsDataProvider' });

// Emailbot API data provider
app.use(EmailbotDataProvider, { name: 'emailbotDataProvider' });

// Emailbot No Auth API data provider
app.use(EmailbotNoAuthDataProvider, { name: 'emailbotNoAuthDataProvider' });

// IntegrationsPartners API data provider
app.use(IntegrationsPartnersDataProvider, { name: 'integrationsPartnersDataProvider' });

// Data Consuming System API data provider
app.use(DataConsumingSystemDataProvider, { name: 'dataConsumingSystemDataProvider' });

// ENCS API data provider
app.use(EncsDataProvider, { name: 'encsDataProvider' });

import IdleVue from 'idle-vue';

app.use(IdleVue, {
    store: vuexStore,
    idleTime: process.env.VUE_APP_IDLE_TIME || 180000, // default to 180 seconds or 3 minutes
    startAtIdle: false,
});

// Highlight directive
app.directive('highlight', {
    mounted: function (element, binding) {
        element.innerHTML = element.innerText.replace(new RegExp(binding.value.query, 'gi'), match => {
            return '<span class="font-bold text-black">' + match + '</span>';
        });
    },
});

app.mount('#app');
