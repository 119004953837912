const actions = [
    {
        routeName: 'notice_to_vacate.index',
        items: [
            {
                title: 'create a new ntv',
                routeName: 'notice_to_vacate.generalInformation',
            },
        ],
    },
    {
        routeName: 'notice_to_vacate.feesAndProrating',
        items: [
            {
                title: 'add charges or credits',
                routeName: 'notice_to_vacate.addCharges',
            },
        ],
    },
];

export default actions;
