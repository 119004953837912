<template>
  <div
      ref="container"
      class="collapsible-tags"
  >
    <template v-if="items.length">
        <div
            ref="wrapper"
            class="collapsible-tags__tags"
        >
            <component
                :is="labelTagComponent"
                v-for="(tagItem, index) of items"
                ref="tags"
                :key="tagItem[trackBy] || index"
                :text="tagItem[label]"
                :deletable="tagItem.clearable && !readonly"
                :disabled="disabled"
                v-bind="titleTagProps"
                @delete="$emit('tagDelete', tagItem)"
                @click="$emit('tagClick', tagItem)"
            >
                <template
                    v-if="hasLabelTooltipTitle"
                    #tooltipTitle
                >
                  <!-- @slot Tooltip content for tag -->
                    <slot name="labelTooltipTitle" :item="tagItem" />
                </template>
            </component>

            <component
                :is="restTagComponent"
                v-if="itemsToHideCount > 0"
                :disabled="disabled"
                :interactive="expandable"
                @click="toggleExpand"
                v-bind="tooltipPosition"
            >
                <template #default>
                    <template v-if="!expanded">
                        +{{ itemsToHideCount }}
                    </template>
                    <template v-else>
                        <ChevronLeftIcon class="h-5" />
                    </template>
                </template>

                <template
                    v-if="hasRestTooltipTitle"
                    #tooltipTitle
                >
                  <!-- @slot Tooltip content for hidden items tag -->
                    <slot
                        name="restTooltipTitle"
                        :hidden-items="hiddenItems"
                    />
                </template>
            </component>
        </div>
    </template>

    <template v-else>
       <!-- @slot Empty placeholder if there are no items -->
      <slot name="empty-placeholder">
        <Tag
            text="all"
            :deletable="false"
            class="tag"
            title="all"
        />
      </slot>
    </template>
  </div>
</template>

<script>
import Tag from "@/components/ui/Tag";
import ChevronLeftIcon from "@/components/ui/icons/ChevronLeftIcon";
import TagWithTooltip from "@/components/ui/TagWithTooltip";

const TAG_HIDDEN_CLASS = 'tag--hidden';
const INITIAL_TAG_HEIGHT = 28;

export default {
  components: {ChevronLeftIcon, Tag, TagWithTooltip},

  props: {
    /**
     *  Label property name to look for in items
     */
    label: {
      type: String,
      required: false,
      default: 'text',
    },

    /**
     * Tag items to display. The format is:
     * ```
     * {
     *     text: '...',
     *     clearable: true,
     * }
     * ```
     *
     * `text` is required and `clearable` is optional here
     */
    items: {
      type: Array,
      required: true,
    },

    /**
     * Maximum number of rows visible in collapsed state
     */
    maxRows: {
      type: Number,
      default: 2,
    },

    /**
     * If tags can be deletable
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow to expand cropped items
     */
    expandable: {
      type: Boolean,
      default: false,
    },

    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Props for title <Tag />
     */
    titleTagProps: {
        type: Object,
        default: () => ({}),
    },

    /**
     * Props for tooltip position <TagWithTooltip />
     */
     tooltipPosition: {
        type: Object,
        default: () => ({}),
    },

    /**
     * Tag prop name that can uniquely identify each row
     */
    trackBy: {
      type: String,
      default: "id",
    },

  },

  emits: [
    'tagDelete',
    'tagClick',
  ],

  data() {
    return {
      expanded: false,
      itemsToHideCount: 0,
      intersectionObserver: null,
      resizeObserver: null,
      tagHeight: INITIAL_TAG_HEIGHT,
    };
  },

  computed: {
    maxHeight() {
      return this.maxRows * this.tagHeight;
    },

    hasLabelTooltipTitle() {
      return !!this.$slots['labelTooltipTitle'];
    },
    
    hasRestTooltipTitle() {
      return !!this.$slots['restTooltipTitle'];
    },

    labelTagComponent() {
      return this.getTagComponent(this.hasLabelTooltipTitle);
    },
    
    restTagComponent() {
      return this.getTagComponent(this.hasRestTooltipTitle);
    },

    hiddenItems() {
      return this.items && this.items.slice(this.items.length - this.itemsToHideCount, this.items.length);
    },
  },

  watch: {
    items: {
      handler() {
        this.showAllTags();
        this.$nextTick(() => {
          if (!this.$refs.tags) return;

          if (this.expanded) {
            this.showAllTags();
          } else {
            this.hideExtraTags();
          }
        });
      },
      immediate: true,
      deep: true,
    },

    expanded(value) {
      if (value) {
        this.showAllTags();
      } else {
        this.hideExtraTags();
      }
    },
  },

  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },

    getTagComponent(hasTooltip) {
      return hasTooltip ? 'TagWithTooltip' : 'Tag';
    },

    hideExtraTags() {
      if (!this.$refs.tags) return;

      const { tags } = this.$refs;
      let firstIndexToHide = tags.findIndex(({ $el }) => $el.offsetTop >= this.maxHeight);
      // width of +n button with big number (just in case)
      const REST_BTN_WIDTH = 40;
      // right margin
      const REST_BTN_MARGIN = 4;

      if (firstIndexToHide < 0 && !this.expanded) {
        firstIndexToHide = this.getFirstHiddenIndex();
      }

      if (firstIndexToHide === 0) { 
        return;
      }

      if (firstIndexToHide > 0) {
        const lastToShow = tags[firstIndexToHide - 1].$el;
        const placeAtRight = lastToShow.offsetParent.clientWidth - lastToShow.offsetLeft - lastToShow.offsetWidth - REST_BTN_MARGIN;
        if (placeAtRight < REST_BTN_WIDTH && firstIndexToHide > 1) {
          // hide one more tag to fit "+n" button
          firstIndexToHide -= 1;
        }

        for (const index in tags) {
          tags[index].$el.classList[index < firstIndexToHide ? 'remove' : 'add'](TAG_HIDDEN_CLASS);
        }
      } else {
        this.showAllTags();
      }

      this.updateHiddenCount();
    },

    showAllTags() {
      if (!this.$refs.tags) return;

      for (const tag of this.$refs.tags) {
        tag.$el.classList.remove(TAG_HIDDEN_CLASS);
      }
    },

    updateExtraTags() {
      this.showAllTags();
      this.hideExtraTags();
    },

    updateHiddenCount() {
      const firstHiddenIndex = this.getFirstHiddenIndex();

      this.itemsToHideCount = firstHiddenIndex >= 0 ? this.items.length - firstHiddenIndex : 0;
    },

    getFirstHiddenIndex() {
      return this.$refs.tags.findIndex(({ $el }) => $el.classList.contains(TAG_HIDDEN_CLASS));
    },

    initResizeObserver(){
      this.resizeObserver = new ResizeObserver(() => {
        if (this.expanded) {
          return;
        }

        window.requestAnimationFrame(() => {
            this.updateExtraTags();
        });
      })
  
      this.resizeObserver.observe(this.$refs.wrapper);
    },

    destroyResizeObserver(){
      if(this.resizeObserver){
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
    },

    initIntersectionObserver() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                this.defineTagHeight();
            }
        }, {
            root: this.$refs.container,
            threshold: 0,
        });

        this.intersectionObserver.observe(this.$refs.wrapper);
    },

    destroyIntersectionObserver() {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
        }
    },

    defineTagHeight() {
        const tagElement = this.$refs.tags?.[0]?.$el;

        if (tagElement) {
            const styles = getComputedStyle(tagElement);

            const margins = ['margin-top', 'margin-bottom']
                .reduce((total, margin) => total + parseInt(styles[margin]), 0);

            this.tagHeight = tagElement.offsetHeight + margins;
        }
    },
  },

  mounted() {
    if(this.$refs.wrapper){
      this.initResizeObserver();
      this.initIntersectionObserver();
    }
  },

  beforeUnmount() {
    this.destroyResizeObserver();
    this.destroyIntersectionObserver();
  }
}
</script>

<style scoped>
.collapsible-tags {
  @apply flex;
}

.collapsible-tags__tags {
  @apply flex flex-wrap overflow-hidden relative flex-grow;
}

.tag {
  max-width: 150px;

  &--hidden {
    @apply hidden;
  }
}
</style>
