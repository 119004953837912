export default {
    permissions: '/v1/user/permissions',
    resident: {
        getReceivableBalances: ['GET', '/resident/v1/receivable/{accountId}'],
        getChargeTotals: ['GET', '/resident/v1/totals/{accountId}'],
    },
    journalEntries: {
        getList: ['GET', '/v1/ledger-entry'],
        getOne: ['GET', '/journal-entries/journal_entry_details/{journal_entries_id}'],
        getAccountGLDetails: ['GET', '/journal-entries/gl_account_details_by_id/{id}'],
        create: ['POST', '/journal-entries'],
        import: ['POST', '/journal-entries/import'],
        delete: ['DELETE', `/journal-entries/{id}`],
        getAttachmentData: ['GET', 'v1/ledger-entry/file/{attachmentId}'],
    },
    quextUser: {
        getOne: ['GET', '/quext-user/{user_id}'],
    },
    bankReconciliation: {
        getList: ['GET', '/v1/reconciliations'],
        getLedgers: ['GET', '/v1/reconciliations/ledgers'],
        getOne: ['GET', '/v1/reconciliations/{reconciliationId}'],
        getSummaryReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary'],
        getOutstandingChecksReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary/checks'],
        getOutstandingCreditsReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary/credits'],
        getDepositsInTransitReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary/deposits'],
        getOutstandingDebitsReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary/debits'],
        getOutstandingBankTransactionsReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary/bank-transactions'],
        getReconciledTransactionsReport: ['GET', '/v1/reconciliations/{reconciliationId}/summary/reconciled-transactions'],
        createReconciliation: ['POST', '/v1/reconciliations'],
        importBankStatements: ['POST', '/v1/reconciliations/bank-statements'],
        adjust: ['POST', '/v1/adjustments/{reconciliationId}'],
        matchReconciliation: ['PUT', '/v1/reconciliations/{reconciliationId}'],
        updateReconciliationStatus: ['PATCH', '/v1/reconciliations/{reconciliationId}'],
        reset: ['PATCH', '/v1/reconciliations/{reconciliationId}'],
        deleteReconciliation: ['DELETE', '/v1/reconciliations/{reconciliationId}'],
    },
    bankAccounts: {
        getList: ['GET', '/v1/bank-accounts'],
        activate: ['PUT', '/v1/bank-accounts/{id}/active'],
        getOne: ['GET', '/v1/bank-accounts/{id}'],
        create: ['POST', '/v1/bank-accounts'],
        update: ['PUT', '/v1/bank-accounts/{id}'],
        getAttachmentData: ['GET', 'v1/bank-accounts/file/{attachmentId}'],
        getCheckTemplates: ['GET', '/v1/checks/templates'],
    },
    ledgerEntry: {
        getList: ['GET', '/v1/ledger-entry'],
        getOne: ['GET', '/v1/ledger-entry/{chartAccountId}'],
        details: ['GET', '/v1/ledger-entry/details/{pairId}'],
        create: ['POST', '/v1/ledger-entry'],
        update: ['PUT', '/v1/ledger-entry/details/{pairId}'],
    },
    fileUpload: {
        getOne: ['GET', '/fileUpload/{attachment_id}'],
        create: ['POST', '/fileupload'],
    },
    codeCategory: {
        getList: ['GET', '/codecategory'],
        check: ['POST', '/codecategory/check_code_category_name'],
        create: ['POST', '/codecategory'],
        delete: ['DELETE', '/codecategory/{id}'],
    },
    code: {
        getOne: ['GET', '/code/{id}'],
        getCategory: ['GET', '/code/code_category/{id}'],
        create: ['POST', '/code'],
        update: ['PATCH', '/code/{id}'],
        delete: ['DELETE', '/code/{id}'],
    },
    invoiceRepeatType: {
        getList: ['GET', '/invoice-repeat-type'],
    },
    deposit: {
        getOne: ['GET', '/v1/deposits/security/{depositId}'],
        getList: ['GET', '/v1/deposits/{accountId}'],
        getSecurity: ['GET', '/v1/deposits/security'],
        getBatchIds: ['GET', '/v1/deposits/batches'],
        exportDeposits: ['GET', '/v1/deposits/export'],
        getAvailablePaymentMethods: ['GET', '/v1/accounts/{accountId}/payment-methods'],
        create: ['POST', '/v1/deposits'],
        revert: ['POST', '/v1/deposits/revert/{depositId}'],
        move: ['POST', '/v1/deposits/move/{depositId}'],
        updateBatch: ['PATCH', '/v1/deposits/untie/{ledgerEntryId}'],
    },
    charges: {
        getList: ['GET', 'v1/charges'],
        getFinancialDetails: ['GET', 'receivable/v1/totals/{accountId}'],
        getOne: ['GET', 'v1/charges/{id}'],
        getCharge: ['GET', '/v1/charges/search'],
        getReport: ['GET', '/v1/charges/export'],
        getConcessionsList: ['GET', '/v1/charges/concessions/{accountId}'],
        create: ['POST', 'v1/charges'],
        createConcession: ['POST', '/v1/charges/concessions'],
        writeOff: ['POST', 'v1/charges/{id}/write-off'],
        deleteSecurityDeposit: ['POST', '/v1/deposits/security/{securityDepositId}/revert'],
        update: ['PATCH', 'v1/charges/{id}'],
        deleteCharge: ['DELETE', 'v1/charges/{id}'],
    },
    credits: {
        getList: ['GET', '/v1/credits'],
        getOne: ['GET', '/v1/credits/{id}'],
        getStatistic: ['GET', '/v1/credits/statistic'],
        create: ['POST', '/v1/credits'],
        delete: ['DELETE', '/v1/credits/{id}'],
    },
    codes: {
        getList: ['GET', '/v1/codes'],
        getOne: ['GET', '/v1/codes/{id}'],
        create: ['POST', '/v1/codes'],
        update: ['PATCH', '/v1/codes/{id}'],
        delete: ['DELETE', '/v1/codes/{id}'],
    },
    codesCategories: {
        getList: ['GET', '/v1/code-categories'],
        create: ['POST', '/v1/code-categories'],
        delete: ['DELETE', '/v1/code-categories/{id}'],
    },
    chartAccounts: {
        getList: ['GET', '/v1/chart-accounts'],
        getOne: ['GET', '/v1/chart-accounts/{chartAccountId}/balance'],
        getCategories: ['GET', '/v1/chart-account-category'],
        create: ['POST', '/v1/chart-accounts'],
        import: ['POST', '/v1/chart-accounts/import'],
        update: ['PUT', '/v1/chart-accounts/{id}'],
        exportFiles: ['GET', '/v1/chart-accounts/export'],
    },
    chartAccountCategory: {
        getList: ['GET', '/v1/chart-account-category'],
    },
    lateFeeSchemas: {
        getList: ['GET', '/v1/late-fee-schemas'],
        getOne: ['GET', '/v1/late-fee-schemas/{id}'],
        delete: ['DELETE', '/v1/late-fee-schemas/{id}'],
        create: ['POST', '/v1/late-fee-schemas'],
        update: ['PATCH', '/v1/late-fee-schemas/{lateFeeSchemaId}'],
    },
    reversalReasons: {
        getList: ['GET', '/v1/reversal-reasons'],
        update: ['PUT', '/v1/reversal-reasons'],
    },
    nsfSettings: {
        getOne: ['GET', '/v1/nsf-settings'],
        create: ['POST', '/v1/nsf-settings'],
        update: ['PUT', '/v1/nsf-settings'],
        delete: ['DELETE', '/v1/accounts/{accountId}/nsf-limits'],
    },
    chargePriority: {
        getList: ['GET', '/v1/codes/priority'],
        update: ['PUT', '/v1/codes/priority'],
    },
    states: {
        getList: ['GET', '/v1/states'],
    },
    glSetup: {
        getList: ['GET', '/v1/chart-account-settings'],
        update: ['PUT', '/v1/chart-account-settings'],
    },
    glClosingPeriod: {
        getList: ['GET', '/v1/close-periods'],
        getSettings: ['GET', '/v1/business-unit-setting'],
        create: ['POST', '/v1/business-unit-setting'],
        updateSettings: ['PUT', '/v1/business-unit-setting'],
        updatePeriod: ['PATCH', '/v1/close-periods/{id}'],
        getActivitiesList: ['GET', '/v1/year-closure-activities'],
        createActivity: ['POST', '/v1/year-closure-activities'],
        updateActivity: ['PUT', '/v1/year-closure-activities/{closureActivityId}'],
        deleteActivity: ['DELETE', '/v1/year-closure-activities/{closureActivityId}'],
        getOneActivity: ['GET', '/v1/year-closure-activities/{closureActivityId}'],
        getSchedules: ['GET', '/v1/year-closure-schedules'],
        saveNewSchedule: ['POST', '/v1/year-closure-schedules'],
        updateSchedule: ['PUT', '/v1/year-closure-schedules/{scheduleId}'],
        updateStatus: ['PATCH', '/v1/year-closure-schedules/{scheduleId}/year-closure-activities/{activityId}/status'],
        closeYear: ['PATCH', '/v1/year-closure-schedules/{scheduleId}/close'],
    },
    glNotifications: {
        getList: ['GET', '/v1/close-periods/notifications'],
        create: ['POST', '/v1/close-periods/notifications'],
        update: ['PUT', '/v1/close-periods/notifications'],
        delete: ['DELETE', '/v1/close-periods/notifications'],
        getCustomerUsers: ['GET', '/auth/v1/customers/{customerId}/customer-users'],
    },
    downloadingSampleFiles: {
        download: ['GET', '/v1/templates'],
    },
    invoices: {
        getList: ['GET', '/v1/invoices'],
        getOne: ['GET', '/v1/invoices/{id}'],
        getTotals: ['GET', '/v1/invoices/totals'],
        getPayees: ['GET', '/v1/invoices/payees'],
        create: ['POST', '/v1/invoices'],
        createRefund: ['POST', '/v1/invoices/refund'],
        import: ['POST', '/v1/invoices/import'],
        update: ['PUT', '/v1/invoices/{id}'],
        updateRefund: ['PUT', '/v1/invoices/refund/{id}'],
        postInvoice: ['PATCH', '/v1/invoices/{id}'],
        void: ['PATCH', '/v1/invoices/{invoiceId}/void'],
        delete: ['DELETE', '/v1/invoices/{invoiceId}'],
    },
    invoiceAttachments: {
        getList: ['GET', '/v1/invoices/{invoiceId}'],
        download: ['GET', '/v1/invoices/file/{id}'],
        delete: ['DELETE', '/v1/invoices/file/{id}'],
    },
    invoiceVoidReasons: {
        getList: ['GET', '/v1/invoices/void-reasons'],
    },
    payments: {
        getList: ['GET', '/v1/ap-payments'],
        create: ['POST', '/v1/ap-payments'],
        delete: ['DELETE', '/v1/ap-payments/{apPaymentId}'],
        cancel: ['PATCH', '/v1/ap-payments/{apPaymentId}/cancel'],
        getDetails: ['GET', '/v1/ap-payments/{id}'],
        checks: ['GET', '/v1/checks'],
        printCheck: ['POST', '/v1/checks/print'],
        rePrintCheck: ['POST', '/v1/checks/reprint'],
        downloadCheck: ['GET', '/v1/checks/download'],
        exportCheck: ['GET', '/v1/checks/download/csv'],
    },
    vendors: {
        getList: ['GET', 'v1/vendors'],
        getOne: ['GET', 'v1/vendors/{id}'],
        create: ['POST', 'v1/vendors'],
        update: ['PUT', 'v1/vendors/{id}'],
        delete: ['DELETE', '/v1/vendors/{id}'],
        export: ['GET', '/v1/vendors/export'],
    },
    vendorsSettings: {
        update: ['PATCH', '/v1/vendors/{id}/settings'],
    },
    vendorsArchiving: {
        update: ['PATCH', '/v1/vendors/{id}/archiving'],
    },
    vendorsContacts: {
        getList: ['GET', '/v1/vendors/{id}/contacts'],
        update: ['PUT', '/v1/vendors/{id}/contacts'],
        delete: ['DELETE', '/v1/vendors/{id}/contacts/{vendorContactId}'],
    },
    vendorsContract: {
        getOne: ['GET', '/v1/vendors/{id}/contract'],
        create: ['POST', '/v1/vendors/{id}/contract'],
        update: ['PATCH', '/v1/vendors/{id}/contract'],
        delete: ['DELETE', '/v1/vendors/{id}/contract'],
    },
    vendorsInsurance: {
        getOne: ['GET', '/v1/vendors/{id}/insurance'],
        create: ['POST', '/v1/vendors/{id}/insurance'],
        update: ['PUT', '/v1/vendors/{id}/insurance'],
        delete: ['DELETE', '/v1/vendors/{id}/insurance'],
    },
    vendorsTax: {
        getOne: ['GET', '/v1/vendors/{id}/tax'],
        create: ['POST', '/v1/vendors/{id}/tax'],
        update: ['PATCH', '/v1/vendors/{id}/tax'],
        exists: ['POST', '/v1/vendors/tax-exists'],
    },
    vendorsName: {
        exists: ['POST', '/v1/vendors/validate'],
    },
    vendorsAttachments: {
        getList: ['GET', '/v1/vendors/{vendorId}/attachments'],
        create: ['POST', '/v1/vendors/{vendorId}/attachments'],
        download: ['GET', '/v1/vendors/{vendorId}/attachments/{vendorAttachmentId}'],
        update: ['PATCH', '/v1/vendors/{vendorId}/attachments/{vendorAttachmentId}'],
        delete: ['DELETE', '/v1/vendors/{vendorId}/attachments/{vendorAttachmentId}'],
    },
    industries: {
        getList: ['GET', 'v1/industries'],
    },
    vendorsListColumns: {
        getList: ['GET', '/v1/vendors/settings/visible-columns'],
        update: ['PUT', '/v1/vendors/settings/visible-columns'],
    },
    vendorsNotificationsContacts: {
        getList: ['GET', '/v1/vendor-notifications/{vendorId}/contacts'],
        create: ['POST', '/v1/vendor-notifications/{vendorId}/contacts'],
        update: ['PUT', '/v1/vendor-notifications/{vendorId}/contacts/{notificationContactId}'],
        delete: ['DELETE', '/v1/vendor-notifications/{vendorId}/contacts/{notificationContactId}'],
        getExpiration: ['GET', '/v1/vendor-notifications/contracts/check-expiration'],
    },
    vendorsLedger: {
        getList: ['GET', '/v1/vendors/{vendorId}/ledger/invoices'],
        getTotals: ['GET', '/v1/vendors/{vendorId}/ledger/invoices/totals'],
    },
    vendorsLedgerDownload: {
        getList: ['GET', '/v1/vendors/{vendorId}/ledger/invoices/{invoiceId}/attachments'],
    },
    vendorBalanceDetailed: {
        getList: ['GET', '/v1/vendors/{vendorId}/ledger/balance'],
    },
    vendorsHistory: {
        getList: ['GET', 'v1/vendors/{vendorId}/history'],
    },
    investors: {
        getList: ['GET', 'v1/investors'],
        getOne: ['GET', '/v1/investors/{id}'],
        create: ['POST', '/v1/investors'],
        update: ['PUT', '/v1/investors/{id}'],
        updateStatus: ['PATCH', '/v1/investors/{id}'],
    },
};
