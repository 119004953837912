export default {
    guestCards: '/guest-cards',

    // guest card detail
    guestCardDetail: {
        get: ['GET', '/guest-cards/{guestCardId}'],
        update: ['PUT', '/guest-cards/{guestCardId}'],
        close: ['PUT', '/guest-card/{guestCardId}/close'],
    },
    tasks: 'tasks',
    tours: '/tours',
    notes: '/notes',
    reasonsToMove: '/reasons',
    usersAssigned: '/users-list',
    contacts: {
        get: ['GET', '/contacts/{contactId}'],
        list: ['GET', '/contacts'],
    },

    occupants: {
        create: ['POST', '/occupants'],
        update: ['PUT', '/occupants/{occupantId}'],
        delete: ['DELETE', '/occupants/{occupantId}'],
    },

    //get community form options
    communityFormOptions: '/community-form-options',

    community_settings: '/settings',

    referral_sources: {
        get: ['GET', '/settings/referrals'],
        update: ['PUT', '/settings/referrals'],
    },

    close_reasons: {
        get: ['GET', '/settings/reasons'],
        update: ['PUT', '/settings/reasons'],
    },

    leasing: {
        getAllUnits: ['GET', '/leasing/units'],
        getAvailableUnits: ['GET', '/leasing/available-units'],
        createApplication: ['POST', '/leasing/create-application'],
    },
    calendar: {
        getTimeSlot: ['GET', '/calendar/available-time-slots'],
    },

    // CONTACT LOOKUP FOR CREATE GUEST CARD RETURNS CONTACT OBJECT PARAMS ARE FIRSTNAME, LASTNAME, EMAIL AND CONTACT NUMBER1
    contactLookup: '/contact-lookup',
    addFollowUp: '/add-followup',
    // UPDATE STATUS OF TASK FROM OPEN TO CLOSED
    taskCompleted: '/task-completed',
    reopenTask: '/task-reopen',
    communityLeadSources: '/community-lead-sources',
    //SETTINGS APIs
    saveCommunityLeadSources: '/update-lead-sources',
    initComunitiesLeadSources: '/initialize-communities-lead-sources',
    copyOtherCommunitiesLeadSources: '/get-community-lead-source',
    closeReasons: {
        get: ['GET', '/settings/reasons'],
        update: ['PUT', '/settings/reasons'],
    },
    //LOC2 function calls
    getAvailableUnits: '/fetch-available-units',
    livingUnits: '/living/units',
};
