export default {
    audience: {
        getList: ['GET', 'event-audience/{eventCategoryId}'],
        update: ['PUT', 'event-audience/{eventCategoryId}'],
    },
    notificationsConfig: {
        getList: ['GET', '/event-configs'],
        update: ['PUT', '/event-configs'],
    },
};
