export default {
    parameters: {
        getList: ['GET', '/parameters'],
        getPartnerParameter: ['GET', '/parameters/getPartnerParameter/{partnerId}'],
        getPartnerCustomerParameter: ['GET', '/parameters/getPartnerCustomerParameter/{partnerId}/{customerId}'],
        getPartnerCustomerCommunityParameter: ['GET', '/parameters/getPartnerCustomerCommunityParameter/{partnerId}/{communityId}'],
        getParameterType: ['GET', '/parameters/types/dropdown'],
        create: ['POST', '/parameters'],
        update: ['PATCH', '/parameters/{id}'],
        updateAll: ['PUT', '/parameters/update-all'],
        createPartnerParameter: ['POST', '/parameters/partner-parameter'],
        createPartnerCustomerParameter: ['POST', '/parameters/partner-customer-parameter'],
        createPartnerCustomerCommunityParameter: ['POST', '/parameters/partner-customer-community-parameter'],
        updatePartnerParameter: ['PATCH', '/parameters/partner-parameter/{id}'],
        updatePartnerCustomerParameter: ['PATCH', '/parameters/partner-customer-parameter/{id}'],
        updatePartnerCustomerCommunityParameter: ['PATCH', '/parameters/partner-customer-community-parameter/{id}'],
        getAllPartnerParameters: ['GET', '/parameters/getAllPartnerParameter/{partnerId}'],
        getAllPartnerCustomerParameter: ['GET', '/parameters/getAllPartnerCustomerParameter/{partnerId}'],
        getAllPartnerCommunityCustomerParameter: ['GET', '/parameters/get-all/partner-community-customer-parameter'],
        copyParameter: ['POST', '/parameters/copyParameter'],
        moveParameter: ['POST', '/parameters/moveParameter'],
        getParameterCategories: ['GET', '/parameter-category/all'],
        deleteGlobalParameter: ['DELETE', '/parameters/deleteGlobalParameter/{id}'],
        deletePartnerParameter: ['DELETE', '/parameters/deletePartnerParameter/{id}'],
        deletePartnerCustomerParameter: ['DELETE', '/parameters/deletePartnerCustomerParameter/{id}'],
        deletePartnerCustomerCommunityParameter: ['DELETE', '/parameters/deletePartnerCustomerCommunityParameter/{id}'],
    },

    parameterValues: {
        getList: ['GET', '/parameters/get/filterParameter'],
    },

    category: {
        getList: ['GET', '/category'],
        create: ['POST', '/category'],
        update: ['PATCH', '/category/{id}'],
        getAll: ['GET', '/category/all'],
        updateAll: ['PUT', '/category/update-all'],
    },

    parameterCategory: {
        getList: ['GET', '/parameter-category'],
        create: ['POST', '/parameter-category'],
        update: ['PATCH', '/parameter-category/{id}'],
        getAll: ['GET', '/parameter-category/all'],
        updateAll: ['PUT', '/parameter-category/update-all'],
    },

    purpose: {
        getList: ['GET', '/purpose'],
        create: ['POST', '/purpose'],
        getByCategory: ['GET', '/purpose/getByCategory/{categoryId}'],
        getPurposeByPartner: ['GET', '/purpose/getPurposeByPartner/{partnerId}'],
        assignPartnerPurposeEndpoint: ['POST', '/purpose/assignPartnerPurposeEndpoints/{partnerId}/{purposeId}/{partnerEndpointId}'],
        unAssignPartnerPurposeEndpoint: ['POST', '/purpose/unAssignPartnerPurposeEndpoints/{partnerId}/{purposeId}/{partnerEndpointId}'],
        getPurposeEndpointByPartner: ['GET', '/purpose/getPurposeEndpointByPartner/{partnerId}'],
        assignPartnerPurpose: ['POST', '/purpose/assignPartnerPurpose/{partnerId}/{purposeId}'],
        unAssignPartnerPurpose: ['POST', '/purpose/unAssignPartnerPurpose/{partnerId}/{purposeId}'],
        getOne: ['GET', '/purpose/{id}'],
        update: ['PATCH', '/purpose/{id}'],
        updateAll: ['PUT', '/purpose/update-all'],
        getPurposeType: ['GET', '/purpose/purposeType/values'],
        getPartnerPurposeEndpoint: ['GET', '/purpose/getPurposeEndpointByPartner/{partnerId}'],
        getPurposeByCommunity: ['GET', '/purpose/getPurposeByCommunity/{communityId}'],
        getCommunityByPurpose: ['GET', 'purpose/getCommunityByPurpose/{purposeId}/{partnerId}'],
        assignCommunityPurpose: ['POST', '/purpose/assignCommunityPurpose/{communityId}/{purposeId}'],
        unAssignCommunityPurpose: ['POST', '/purpose/unAssignCommunityPurpose/{communityId}/{purposeId}'],
        getPartnersByPurpose: ['GET', 'purpose/getPartnersByPurpose/{purposeId}'],
        getPurposeByPartnerCommunity: ['GET', '/purpose/getPurposeByPartnerCommunity/{partnerId}/{communityId}'],
        getAllDataByPurpose: ['GET', 'purpose/getAllDataByPurpose/{partnerId}/{purposeId}'],
        getPurposeBySystem: ['GET', '/purpose/system/{partnerSystemId}'],
        getCommunityEndpointsByPurpose: ['GET', '/purpose/getCommunityEndpointsByPurpose/{partnerId}/{communityId}/{purposeIds}'],
    },

    partner: {
        getOne: ['GET', '/partner/{id}'],
        getPartnerCommunity: ['GET', '/partner/getPartnerCommunity/{partnerId}'],
        getPartnerTree: ['GET', '/partner/getPartnerTree/{partnerId}'],
        getList: ['GET', '/partner'],
        create: ['POST', '/partner'],
        update: ['PATCH', '/partner/{id}'],
        assignPartnerCommunity: ['POST', '/partner/assignPartnerCommunity'],
        unAssignPartnerPurpose: ['POST', '/partner/unAssignPartnerCommunity/{partnerId}/{communityId}'],
        assignPartnerCommunityEndpoint: ['POST', '/partner/assignPartnerCommunityEndpoint'],
        unAssignPartnerCommunityPurposeEndpoint: [
            'POST',
            '/partner/unAssignPartnerCommunityPurposeEndpoint/{partnerId}/{communityId}/{purposeId}/{partnerEndpointId}',
        ],
        getPurposeCommunityEndpointByPartner: ['GET', '/partner/getPurposeCommunityEndpointByPartner/{partnerId}'],
        fileUpload: ['POST', '/partner/file-upload'],
        getAll: ['GET', '/partner/all'],
        updateAll: ['PUT', '/partner/update-all'],
        getCommunitiesByPartnerId: ['GET', '/partner/getCommunitiesByPartnerId/{partnerId}/{customerId}'],
        getPartnerCustomersByPartnerId: ['GET', '/partner/getPartnerCustomersByPartnerId/{partnerId}'],
        getCommunityPurposeEndpointByCommunity: ['GET', '/partner/getCommunityPurposeEndpointByCommunity/{communityId}'],
        unAssignCommunityPartner: ['POST', '/partner/unAssignPartnerCommunity/{partnerId}/{communityId}'],
        getAllpartnerCommunityData: ['GET', '/partner/getAllpartnerCommunityData/{partnerId}/{communityId}'],
        validatePartnerName: ['GET', '/partner/validatePartnerName/{partnerName}'],
        getParameterByPartnerCommunity: ['GET', '/partner/getParameterByPartnerCommunity/{partnerId}/{communityId}'],
        getCommunityPurposeEndpointsByPartnerSystem: ['GET', '/partner/system/{partnerSystemId}'],
        validatePartnerCode: ['GET', '/partner/validatePartnerCode/{partnerCode}'],
        assignCommunityPurposeSystem: ['POST', '/partner/assignCommunityPurposeSystem'],
        unAssignCommunityPurposeSystem: ['POST', '/partner/unAssignCommunityPurposeSystem/{partnerCommunityPurposeId}'],
        getCommunityPurposeSystem: ['GET', '/partner/getCommunityPurposeSystem/{partnerId}'],
        editPartnerCommunity: ['PUT', '/partner/editPartnerCommunity'],
    },

    partnerEnvironment: {
        getList: ['GET', 'partner-environment'],
        create: ['POST', 'partner-environment'],
        update: ['PATCH', 'partner-environment/{id}'],
        deletePartnerEnvironmentById: ['PATCH', 'partner-environment/delete/{id}'],
        getByPartnerId: ['GET', 'partner-environment/all'],
    },

    partnerSystem: {
        getList: ['GET', 'partner-system'],
        create: ['POST', 'partner-system'],
        update: ['PATCH', 'partner-system/{id}'],
        deletePartnerSystemById: ['PATCH', 'partner-system/delete/{id}'],
        getByPartnerId: ['GET', 'partner-system/all'],
    },

    partnerVersion: {
        getList: ['GET', 'partner-version'],
        create: ['POST', '/partner-version'],
        update: ['PATCH', 'partner-version/{id}'],
        deletePartnerVersionById: ['PATCH', 'partner-version/delete/{id}'],
        getByPartnerId: ['GET', 'partner-version/all'],
    },

    partnerEndpoint: {
        getList: ['GET', 'partner-endpoint'],
        create: ['POST', '/partner-endpoint'],
        update: ['PATCH', 'partner-endpoint/{id}'],
        delete: ['PATCH', 'partner-endpoint/delete/{id}'],
        getByPartnerId: ['GET', 'partner-endpoint/all'],
        getOne: ['GET', 'partner-endpoint/{id}'],
        getByEnvironmentId: ['GET', 'partner-endpoint/getByPartnerEnvironmentId/{partnerEnvironmentId}'],
    },

    customer: {
        getList: ['GET', '/customer/get'],
    },

    community: {
        getList: ['GET', '/community/get/{customerId}'],
        getCommunity: ['GET', '/community/getCommunityByCustomerIdCommunityId/{customerId}'],
        getOne: ['GET', '/community/{communityId}'],
        getCommunityPartner: ['GET', '/community/getCommunityPartner/{communityId}'],
        getPartnerByCommunity: ['GET', '/community/getPartnerByCommunity/{communityId}'],
    },

    partnerSecurity: {
        create: ['POST', 'partner-security'],
        update: ['PATCH', 'partner-security/{id}'],
        getList: ['GET', 'partner-security'],
        delete: ['PATCH', 'partner-security/delete/{id}'],
        getByEnvironmentId: ['GET', 'partner-security/getByPartnerEnvironmentId/{partnerEnvironmentId}'],
    },

    partnerCustomerSecurity: {
        create: ['POST', 'partner-customer-security'],
        update: ['PATCH', 'partner-customer-security/{id}'],
        getList: ['GET', 'partner-customer-security'],
        delete: ['PATCH', 'partner-customer-security/delete/{id}'],
        getByEnvironmentId: ['GET', 'partner-customer-security/getByPartnerEnvironmentId/{partnerEnvironmentId}'],
    },

    partnerCommunitySecurity: {
        create: ['POST', 'partner-community-security'],
        update: ['PATCH', 'partner-community-security/{id}'],
        getList: ['GET', 'partner-community-security'],
        delete: ['PATCH', 'partner-community-security/delete/{id}'],
        getByEnvironmentId: ['GET', 'partner-community-security/getByPartnerEnvironmentId/{partnerEnvironmentId}'],
    },
    permissions: '/my/permissions',
};
