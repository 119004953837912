import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.auto_open.create',
        path: '/iot/automation/auto-open/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/auto_open/Edit.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'new auto open',
            title: 'new auto open',
            flowStartsWith: 'iot.auto_open.create',
            permissionChecker: createPermissionChecker('PDK_AUTO_OPEN_CREATE'),
        },
    },
    {
        name: 'iot.auto_open.edit',
        path: '/iot/automation/auto-open/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/auto_open/Edit.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'edit auto open',
            title: 'edit auto open',
            flowStartsWith: 'iot.auto_open.index',
            permissionChecker: createPermissionChecker('PDK_AUTO_OPEN_READ_BY_ID'),
        },
    },
]);
