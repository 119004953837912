const ROUTES = {
    BOOKMARKS: {
        name: 'bookmarks',
        path: '/bookmarks',
    },
    // Accounts receivable
    AR_DEPOSITS: {
        name: 'accounts_receivable.deposits',
        path: '/accounts_receivable/deposits',
    },
    AR_RESIDENT_DEPOSITS: {
        name: 'accounts_receivable.resident_deposits',
        path: '/accounts_receivable/resident_deposits/:id',
    },
    AR_DEPOSITS_ADD_NEW: {
        name: 'accounts_receivable.deposits_add_new',
        path: '/accounts_receivable/deposits/add_new',
    },
    AR_RESIDENT_DEPOSITS_ADD_NEW: {
        name: 'accounts_receivable.resident_deposits_add_new',
        path: '/accounts_receivable/resident_deposits/:id/add_new',
    },
    AR_RESIDENT_DEPOSITS_REVERT: {
        name: 'accounts_receivable.resident_deposits_revert',
        path: '/accounts_receivable/resident_deposits/:id/revert/:depositId',
    },
    AR_RESIDENT_DEPOSITS_MOVE: {
        name: 'accounts_receivable.resident_deposits_move',
        path: '/accounts_receivable/resident_deposits/:id/move/:depositId',
    },
    AR_CHARGES: {
        name: 'accounts_receivable.charges',
        path: '/accounts_receivable/charges',
    },
    AR_RESIDENT_CHARGES: {
        name: 'accounts_receivable.resident_charges',
        path: '/accounts_receivable/resident_charges/:id',
    },
    AR_RESIDENT_ADD_NEW_CONCESSION: {
        name: 'accounts_receivable.add_new_charge_concession',
        path: '/accounts_receivable/add_new_charge_concession/:id',
    },
    AR_RESIDENT_VIEW_DETAILS: {
        name: 'accounts_receivable.view_concession',
        path: '/accounts_receivable/resident_charges/view_concession/:id',
    },
    AR_PLANNED_FUTURE_CHARGES: {
        name: 'accounts_receivable.planned_future_charges',
        path: '/accounts_receivable/planned_future_charges/:id',
    },
    AR_CHARGES_ADD_NEW: {
        name: 'accounts_receivable.resident_charges_add_new',
        path: '/accounts_receivable/resident_charges/:id/add_new',
    },
    AR_CHARGES_VIEW_DETAILS: {
        name: 'accounts_receivable.resident_charges_view_details',
        path: '/accounts_receivable/resident_charges/:id/view/:chargeId',
    },
    AR_CREDITS: {
        name: 'accounts_receivable.credits',
        path: '/accounts_receivable/credits',
    },
    AR_CREDITS_ADD_NEW_CREDIT: {
        name: 'accounts_receivable.add_new_credit',
        path: '/accounts_receivable/add_new_credit',
    },
    AR_CREDITS_VIEW_CREDIT: {
        name: 'accounts_receivable.view_credit',
        path: '/accounts_receivable/credits/view_credit/:id',
    },
    AR_CREDITS_STATISTIC: {
        name: 'accounts_receivable.credits_statistic',
        path: '/accounts_receivable/credits/statistic',
    },
    AR_CREDITS_ADD_NEW_CONCESSION: {
        name: 'accounts_receivable.add_new_credit_concession',
        path: '/accounts_receivable/add_new_credit_concession',
    },
    // General ledger
    GL_CLOSING_PERIOD: {
        name: 'general_ledger.closing_period',
        path: '/general_ledger/closing_period',
    },
    GL_JOURNAL_ENTRIES: {
        name: 'general_ledger.journal_entry_list',
        path: '/general_ledger/journal_entries',
    },
    GL_JOURNAL_ENTRIES_DETAILS: {
        name: 'general_ledger.journal_entry_details',
        path: '/general_ledger/journal_entries/view/:id',
    },
    GL_JOURNAL_ENTRIES_ADD_NEW_ENTRIES: {
        name: 'general_ledger.journal_entry_add_new_entries',
        path: '/general_ledger/journal_entry_add_new_entries',
    },
    GL_JOURNAL_ENTRIES_EDIT: {
        name: 'general_ledger.journal_entries_edit',
        path: '/general_ledger/journal_entries/edit/:id',
    },
    GL_ACCOUNTS: {
        name: 'general_ledger.gl_account_list',
        path: '/general_ledger/gl_accounts',
    },
    GL_ACCOUNTS_DETAILS: {
        name: 'general_ledger.gl_account_details',
        path: '/general_ledger/gl_accounts/:id',
    },
    GL_BANK_RECONCILIATIONS: {
        name: 'general_ledger.bank_reconciliation_list',
        path: '/general_ledger/bank_reconciliation',
    },
    GL_BANK_RECONCILIATION_EDIT: {
        name: 'general_ledger.bank_reconciliation_edit',
        path: '/general_ledger/bank_reconciliation/:id',
    },
    GL_BANK_RECONCILIATION_CREATE: {
        name: 'general_ledger.bank_reconciliation_create',
        path: '/general_ledger/bank_reconciliation/start',
    },
    GL_BANK_RECONCILIATION_REPORT: {
        name: 'general_ledger.bank_reconciliation_report',
        path: '/general_ledger/bank_reconciliation/report/:id',
    },
    GL_BANK_RECONCILIATION_ADD_ADJUSTMENT: {
        name: 'general_ledger.bank_reconciliation_add_adjustment',
        path: '/general_ledger/bank_reconciliation/:id/add_adjustment',
    },
    GL_BANK_ACCOUNTS: {
        name: 'general_ledger.bank_account_list',
        path: '/general_ledger/bank_accounts',
    },
    GL_BANK_ACCOUNT_EDIT: {
        name: 'general_ledger.bank_account_edit',
        path: '/general_ledger/bank_accounts/:id',
    },
    GL_BANK_ACCOUNT_CREATE: {
        name: 'general_ledger.bank_account_create',
        path: '/general_ledger/create_bank_account',
    },
    ACCT_SETTINGS: {
        name: 'accounting.settings',
        path: '/accounting_settings',
    },
    ACCT_SETTINGS_CHART: {
        name: 'accounting.settings_chart',
        path: '/accounting_settings/chart',
    },
    ACCT_SETTINGS_CHART_ADD_NEW: {
        name: 'accounting.settings_chart_add_new',
        path: '/accounting_settings/chart/add_new',
    },
    ACCT_SETTINGS_CHART_EDIT: {
        name: 'accounting.settings_chart_edit',
        path: '/accounting_settings/chart/edit/:id',
    },
    ACCT_CHART_IMPORT: {
        name: 'accounting.settings_chart_import',
        path: '/accounting_settings/chart/import',
    },
    ACCT_SETTINGS_SETUP_EDIT: {
        name: 'accounting.settings_setup_edit',
        path: '/accounting_settings/setup_edit',
    },
    ACCT_SETTINGS_CHARGE_CODES: {
        name: 'accounting.settings_charge_codes',
        path: '/accounting_settings/charge_codes',
    },
    ACCT_SETTINGS_ADD_CHARGE_CODE: {
        name: 'accounting.settings_add_charge_code',
        path: '/accounting_settings/charge_codes/add_new',
    },
    ACCT_SETTINGS_EDIT_CHARGE_CODE: {
        name: 'accounting.settings_edit_charge_code',
        path: '/accounting_settings/charge_codes/edit/:id',
    },
    ACCT_SETTINGS_LATE_FEE_SCHEMAS: {
        name: 'accounting.settings_late_fee_schemas',
        path: '/accounting_settings/late_fee',
    },
    ACCT_SETTINGS_ADD_LATE_FEE_SCHEMA: {
        name: 'accounting.settings_add_schema',
        path: '/accounting_settings/late_fee/add_schema',
    },
    ACCT_SETTINGS_EDIT_LATE_FEE_SCHEMA: {
        name: 'accounting.settings_edit_schema',
        path: '/accounting_settings/late_fee/view_schema/:id',
    },
    ACCT_SETTINGS_TEMPORARY_LATE_FEES_SCHEMA: {
        name: 'accounting.settings_temporary_late_fee_schema',
        path: '/accounting_settings/late_fee/temporary/:id',
    },
    ACCT_SETTINGS_VIEW_TEMPORARY_CHANGED_LATE_FEES_SCHEMA: {
        name: 'accounting.settings_temporary_view_late_fee_schema',
        path: '/accounting_settings/late_fee/temporary/view/:id',
    },
    ACCT_SETTINGS_PAYMENT_REVERSAL_REASONS: {
        name: 'accounting.settings_payment_reversal_reasons',
        path: '/accounting_settings/payment_reversal_reasons',
    },
    ACCT_SETTINGS_NSF: {
        name: 'accounting.settings_nsf',
        path: '/accounting_settings/nsf',
    },
    ACCT_SETTINGS_PAYMENT_PRIORITY: {
        name: 'accounting.settings_payment_priority',
        path: '/accounting_settings/payment_priority',
    },
    // Accounts payable
    AP_INVOICES: {
        name: 'accounts_payable.invoices',
        path: '/accounts_payable/invoices',
    },
    AP_INVOICES_ADD_NEW: {
        name: 'accounts_payable.invoices_add_new',
        path: '/accounts_payable/invoices/add_new',
    },
    AP_INVOICES_EDIT: {
        name: 'accounts_payable.invoices_edit',
        path: '/accounts_payable/invoices/edit/:id',
    },
    AP_INVOICES_IMPORT: {
        name: 'accounts_payable.invoices_import',
        path: '/accounts_payable/invoices/import',
    },
    AP_INVOICES_ADD_REFUND_TO_RESIDENT: {
        name: 'accounts_payable.invoices_add_refund',
        path: '/accounts_payable/invoices/add_refund',
    },
    AP_INVOICES_EDIT_REFUND_TO_RESIDENT: {
        name: 'accounts_payable.invoices_edit_refund',
        path: '/accounts_payable/invoices/edit_refund/:id',
    },
    AP_INVOICES_ADD_PAYMENTS_TO_INVESTORS: {
        name: 'accounts_payable.payments_to_investors_add_new',
        path: '/accounts_payable/invoices/payments_to_investors_add_new',
    },
    AP_INVOICES_EDIT_PAYMENTS_TO_INVESTORS: {
        name: 'accounts_payable.payments_to_investors_edit',
        path: '/accounts_payable/invoices/payments_to_investors/edit/:id',
    },
    AP_INVOICES_FUTURE: {
        name: 'accounts_payable.planned_future_invoices',
        path: '/accounts_payable/planned_future_invoices',
    },
    AP_PAYMENTS: {
        name: 'accounts_payable.payments',
        path: '/accounts_payable/payments',
    },
    AP_PAYMENTS_ADD_NEW: {
        name: 'accounts_payable.payments_add_new',
        path: '/accounts_payable/payments/add_new',
    },
    AP_PAYMENTS_VIEW_DETAILS: {
        name: 'accounts_payable.payments_view_details',
        path: '/accounts_payable/payments/view_details/:id',
    },
    ACCT_SETTINGS_INVESTORS: {
        name: 'accounting.settings_investors',
        path: '/accounting_settings/investors',
    },
    ACCT_SETTINGS_INVESTORS_ADD_NEW: {
        name: 'accounting.settings_investors_add_new',
        path: '/accounting_settings/investors/add_new',
    },
    ACCT_SETTINGS_INVESTORS_EDIT: {
        name: 'accounting.settings_investors_edit',
        path: '/accounting_settings/investors/edit/:id',
    },
    ACCT_SETTINGS_INVESTORS_VIEW: {
        name: 'accounting.settings_investors_view',
        path: '/accounting_settings/investors/view/:id',
    },
    // Vendors
    VENDOR__DETAILS: {
        name: 'vendor.vendor_detail',
        path: '/vendor/vendor_details/:id',
    },
    VENDOR_LIST: {
        name: 'vendor.manage_vendors',
        path: '/vendor/manage_vendors',
    },
    ADD_VENDOR: {
        name: 'vendor.vendor_add',
        path: '/vendor/vendor_add',
    },
    EDIT_VENDOR: {
        name: 'vendor.vendor_edit',
        path: '/vendor/vendor_edit/:id',
    },
    VIEW_VENDOR: {
        name: 'vendor.vendor_view',
        path: '/vendor/vendor_view/:id',
    },
    VENDOR_ARCHIVED: {
        name: 'vendor.vendor_archived',
        path: '/vendor/manage_vendors/archived',
    },
};

export default ROUTES;
