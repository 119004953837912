import automation from './_automation';
import users from './_users';
import locks from './_locks';
import passes from './_passes';
import devices from './_devices';

export default {
    permissions: {
        get: ['GET', 'security/users/current/permissions'],
    },
    roles: {
        get: ['GET', '/security/roles/accessible/{permissionLink}'],
        getList: ['GET', '/security/roles/accessible/{permissionLink}'],
    },
    group_accesses: {
        get: ['GET', '/user-accesses/group/locations'],
        getRoles: ['GET', '/user-accesses/group'],
        delete: ['PUT', '/user-accesses/group/locations/remove'],
    },

    thermostats: {
        create: ['POST', 'devices/thermostats'],
        getList: ['GET', 'devices/thermostats'],
        getOne: ['GET', 'devices/thermostats/{id}'],
        delete: ['DELETE', 'devices/thermostats/{id}'],
        put: ['PUT', 'devices/thermostats/{id}'],
        update: ['PATCH', 'devices/thermostats/{id}'],
        sendRequest: ['POST', 'devices/thermostats/{id}/read/{command}'],
        reset: ['POST', 'devices/thermostats/{id}/reset'],
        replace: ['POST', 'devices/thermostats/{id}/replace'],
        getSettings: ['GET', 'devices/thermostats/{id}/settings'],
        requestScheduleResync: ['POST', 'devices/thermostats/{id}/schedule/resync'],
        switchSupervisoryControlOverride: ['POST', 'devices/thermostats/{id}/switch-supervisory-control-override'],
    },
    not_installed_thermostats: 'devices/thermostats/not-installed',
    thermostat_log: {
        get: ['GET', 'devices/thermostats/{id}/log'],
    },
    thermostat_diagnostic: {
        getList: ['GET', 'devices/thermostats/{id}/diagnostic-log'],
    },
    leak_sensors: {
        getOne: ['GET', '/devices/leak-sensors/{id}'],
        update: ['PUT', '/devices/leak-sensors/{id}'],
        getList: ['GET', '/devices/leak-sensors'],
        create: ['POST', '/devices/leak-sensors/install'],
        install_retry: ['POST', '/devices/leak-sensors/install/retry'],
        uninstall_retry: ['POST', '/devices/leak-sensors/uninstall/retry'],
        settings: ['GET', '/devices/leak-sensors/settings'],
        updateSettings: ['PUT', '/devices/leak-sensors/settings/update'],
        delete: ['DELETE', '/devices/leak-sensors/{id}'],
        forceDelete: ['DELETE', '/devices/leak-sensors/force/{id}'],
    },
    leak_sensors_notification_logs: {
        getList: ['GET', '/zwave/notification-logs/search'],
    },
    locations: {
        getList: ['GET', '/locations'],
        getOne: ['GET', '/locations/{id}'],
        getLinkedDevices: ['GET', '/locations/{id}/devices'],
        getParentsHierarchy: ['GET', '/locations/{id}/parents-hierarchy'],
        create: ['POST', 'locations'],
        update: ['PUT', '/locations/{id}'],
        delete: ['DELETE', '/locations/{id}'],
        getUserAccesses: ['GET', '/locations/{id}/user-accesses'],
        move: ['PUT', '/locations/{id}/set-parent'],
    },
    apartments: '/locations/apartment-centric',
    locationsStaffOnly: '/locations/staff-only',
    locationsCommon: '/locations/common',
    locationsPrimary: '/locations/primary',
    units: '/locations/units',
    add_location: {
        get: ['GET', 'user-accesses/group/locations/accessible'],
        update: ['PUT', 'user-accesses/group/locations/add'],
    },
    staff_access_active: {
        getList: ['GET', '/user-accesses/temporary/active'],
    },
    staff_access_expired: {
        getList: ['GET', '/user-accesses/temporary/inactive'],
    },
    staff_access: {
        getOne: ['GET', 'user-accesses/temporary/{id}'],
        create: ['POST', 'user-accesses/temporary'],
        update: ['PUT', 'user-accesses/temporary/{id}'],
        delete: ['POST', 'user-accesses/temporary/{id}/revoke'],
        getUsers: ['GET', 'devices/locks/credentials/allowed-users'],
        deleteBulk: ['POST', 'user-accesses/temporary/revoke'],
    },
    staff_access_log: {
        getList: ['GET', 'devices/locks/server-commands/history'],
    },
    residents: {
        getList: ['GET', '/security/users/residents'],
        getOne: ['GET', '/security/users/residents/{id}'],
        create: ['POST', '/security/users/residents'],
    },

    residentQueueEvent: {
        getList: ['GET', '/resident-queue/events'],
        getListOfTypes: ['GET', '/resident-queue/events/event-types'],
    },

    users: {
        getList: ['GET', '/security/users'],
        getOne: ['GET', '/security/users/{id}'],
        create: ['POST', '/security/users'],
        update: ['PUT', '/security/users/{id}'],
        emailCheck: ['POST', '/security/users/email-check'],
        deleteInCustomer: ['DELETE', '/security/users/{userId}/customer/{customerId}'],
        deleteInCommunity: ['DELETE', '/security/users/{userId}/community/{communityId}'],
    },

    vendors: {
        create: ['POST', '/security/users/vendors'],
    },

    leases: {
        getList: ['GET', '/leases'],
        getOne: ['GET', '/leases/{id}'],
        update: ['PUT', '/leases/{id}'],
        create: ['POST', '/leases'],
        transfer: ['POST', '/leases/{id}/transfer'],
        renew: ['POST', '/leases/{id}/renew'],
        archive: ['POST', '/leases/{id}/archive'],
        executeMoveIn: ['POST', '/leases/{id}/residents/{userId}/execute-move-in'],
        executeMoveOut: ['POST', '/leases/{id}/residents/{userId}/execute-move-out'],
    },

    leaseAuthorizations: {
        getList: ['GET', '/leases/{id}/accesses'],
        create: ['POST', '/leases/{id}/accesses/create'],
        revoke: ['POST', '/leases/{id}/accesses/revoke'],
    },

    leasesHistory: {
        getList: ['GET', '/leases/{id}/history'],
    },

    userAuthorizations: {
        getList: ['GET', '/security/users/{userId}/authorizations'],
        create: ['PUT', '/security/users/{userId}/authorizations/create'],
        update: ['PUT', '/security/users/{userId}/authorizations/toggle'],
        revoke: ['PUT', '/security/users/{userId}/authorizations/revoke'],
    },

    schedule: {
        getList: ['GET', 'devices/locks/credentials/schedule'],
        getOne: ['GET', 'devices/locks/credentials/schedule/{id}'],
        create: ['POST', 'devices/locks/credentials/schedule'],
        update: ['PUT', 'devices/locks/credentials/schedule/{id}'],
        delete: ['DELETE', 'devices/locks/credentials/schedule/{id}'],
    },

    pacs_statuses: 'devices/locks/pacs/status',
    pacs: {
        getList: ['GET', 'devices/locks/pacs/queue-log'],
        getOne: ['GET', 'devices/locks/pacs/lock-database-transaction/{transactionId}'],
        cancel: ['POST', 'devices/locks/pacs/lock-database-transaction/{transactionId}/cancel'],
        retry: ['POST', 'devices/locks/pacs/lock-database-transaction/retry'],
    },
    staff: {
        getList: ['GET', '/security/users/deprecated-users'],
        addToCommunity: ['PUT', '/security/users/{id}/add-apartment-community'],
    },
    keys: {
        getOne: ['GET', '/devices/locks/credentials/{keyId}'],
        getList: ['GET', '/devices/locks/credentials'],
        groupByLockAccess: ['GET', '/devices/locks/credentials/group-by-lock-access'],
        getCredentialTypes: ['GET', '/devices/locks/credentials/credential-types'],
    },
    fob: {
        create: ['POST', '/devices/locks/credentials/fob'],
        getList: ['GET', '/devices/locks/credentials/fob'],
        import: ['POST', '/devices/locks/credentials/fob/import'],
        issueToUser: ['POST', '/devices/locks/credentials/fob/issue-to-user'],
        update: ['PATCH', '/devices/locks/credentials/fob/{keyId}'],
        delete: ['DELETE', '/devices/locks/credentials/fob'],
        formats: ['GET', '/devices/locks/credentials/fob-formats'],
        usersSummary: ['GET', 'devices/locks/credentials/user-summary'],
    },
    ble: {
        getList: ['GET', '/devices/locks/credentials/ble'],
        issueKey: ['POST', '/devices/locks/credentials/ble/issue'],
        reissueKey: ['POST', '/devices/locks/credentials/ble/{id}/reissue'],
        revokeKey: ['POST', '/devices/locks/credentials/ble/{id}/revoke'],
        create: ['POST', 'devices/locks/credentials/ble'],
        getBleStatus: ['GET', '/devices/locks/credentials/ble/state'],
        setBleStatus: ['PUT', '/devices/locks/credentials/ble/state'],
        setBleStatusByVendor: ['PUT', '/devices/locks/credentials/ble/vendor/state'],
    },

    remote: {
        getList: ['GET', '/devices/locks/credentials/remote'],
        getRemoteStatus: ['GET', '/devices/locks/credentials/remote/state'],
        setRemoteStatus: ['PUT', '/devices/locks/credentials/remote/state'],
        setRemoteStatusByVendor: ['PUT', '/devices/locks/credentials/remote/vendor/state'],
    },
    quext_pass: {
        updateStatus: ['PUT', '/guest/access/user/{userId}/passes/settings'],
    },
    ble_settings: {
        get: ['GET', '/apartment-community/settings/ble'],
        update: ['PATCH', '/apartment-community/settings/ble'],
    },
    pin: {
        getOne: ['GET', '/devices/locks/credentials/pin'],
        issue: ['POST', '/devices/locks/credentials/pin'],
        invalidate: ['DELETE', '/devices/locks/credentials/pin/{id}'],
    },

    userCredentials: {
        countByType: ['GET', '/devices/locks/credentials/user/{userId}/count-by-type'],
    },

    locks_list: '/devices/locks/credentials/{keyId}/locks',
    keyrings: '{userId}/keyrings',
    guest_access_community_settings: {
        get: ['GET', '/guest/access/community/settings'],
        update: ['POST', '/guest/access/community/settings'],
    },
    guest_access_user_settings: {
        get: ['GET', '/guest/access/user/{id}/settings'],
        update: ['POST', '/guest/access/user/{id}/settings'],
    },

    customUserGroups: {
        getList: ['GET', '/user-groups/custom'],
        getOne: ['GET', '/user-groups/custom/{id}'],
        create: ['POST', '/user-groups/custom'],
        update: ['PUT', '/user-groups/custom/{id}'],
        delete: ['DELETE', '/user-groups/custom/{id}'],
        validateNameAvailability: ['POST', '/user-groups/custom/validate-name-availability'],
    },
    customUserGroupsStaffParticipants: {
        getList: ['GET', '/user-groups/custom/participants/staff'],
    },
    customUserGroupsGroupsParticipants: {
        getList: ['GET', '/user-groups/custom/participants/group'],
    },
    customUserGroupsUnitParticipants: {
        getList: ['GET', '/user-groups/custom/participants/unit'],
    },
    customUserGroupsResidentParticipants: {
        getList: ['GET', '/user-groups/custom/participants/resident'],
    },

    integrationQueue: {
        getOne: ['GET', '/ext-events/{id}'],
        getList: ['GET', '/ext-events'],
        getAvailableEventTypes: ['GET', '/ext-events/available-types'],
        markQueued: ['POST', '/ext-events/mark-queued'],
        markAvailableQueued: ['POST', '/ext-events/mark-available-queued'],
        markSkipped: ['POST', '/ext-events/mark-skipped'],
        getCurrentConfig: ['GET', '/ext-events/config'],
        getConfigTypes: ['GET', '/ext-events/auto-applied-types'],
        updateConfig: ['PUT', '/ext-events/config'],
    },

    si_integration: {
        readSettings: ['GET', '/integrations/si/settings'],
        updateSettings: ['POST', '/integrations/si/settings'],
        getAuth: ['GET', '/integrations/si/auth'],
        updateAuth: ['POST', '/integrations/si/auth'],
        deleteAuth: ['DELETE', '/integrations/si/auth'],
    },
    firekey: {
        getSettings: ['GET', '/apartment-community/settings/fire-key'],
        updateSettings: ['PUT', '/apartment-community/settings/fire-key'],
    },

    community_settings: {
        get: ['GET', '/apartment-community/settings'],
        update: ['PUT', '/apartment-community/settings/update'],
    },

    apartmentCommunities: {
        getList: ['GET', '/apartment-communities'],
    },

    community_integration_events: {
        getList: ['GET', '/apartment-communities/{id}/ext-sync'],
        downloadReport: ['GET', '/ext-community-sync/{id}/report'],
        getLastSync: ['GET', '/apartment-communities/{id}/ext-sync/last'],
        getOne: ['GET', '/ext-community-sync/{id}'],
        start: ['POST', '/apartment-communities/{id}/ext-sync'],
        stop: ['POST', '/ext-community-sync/{id}/mark-to-stop'],
    },

    community_integration_errors: {
        getList: ['GET', '/ext-community-sync/{id}/problems'],
    },

    brivo_auth_status: {
        get: ['GET', '/integrations/brivo/authentication/status'],
    },

    community: {
        get: ['GET', 'apartment-communities/current'],
        update: ['PUT', 'apartment-communities/current'],
    },

    rentRollImport: {
        getList: ['GET', '/import/rent-roll/executions'],
        create: ['POST', '/import/rent-roll/import'],
        validate: ['POST', '/import/rent-roll/validate'],
        getOne: ['GET', '/import/rent-roll/executions/{id}'],
        getUsers: ['GET', '/import/rent-roll/executions/{id}/users'],
        getLogs: ['GET', '/import/rent-roll/executions/{id}/log'],
    },

    brivoResync: {
        getList: ['GET', '/integrations/brivo/resync/jobs'],
        update: ['POST', '/integrations/brivo/resync'],
        getOne: ['GET', '/integrations/brivo/resync/jobs/{id}'],
        getReport: ['GET', '/integrations/brivo/resync/jobs/{id}/report'],
    },

    ...automation,
    ...locks,
    ...users,
    ...passes,
    ...devices,
};
