import { createAppAccessChecker } from '@/components/auth/permissions';

export default [
    {
        routeName: 'raaTools.locations.index',
        items: [
            {
                title: 'add new location',
                routeName: 'raaTools.locations.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.occupancies.index',
        items: [
            {
                title: 'add new occupancy',
                routeName: 'raaTools.occupancies.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.occupancies.details',
        items: [],
    },
    {
        routeName: 'raaTools.identities.index',
        items: [
            {
                title: 'add new identity',
                routeName: 'raaTools.identities.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.identities.details',
        items: [],
    },
    {
        routeName: 'raaTools.persons.index',
        items: [
            {
                title: 'add new person',
                routeName: 'raaTools.persons.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.persons.edit',
        items: [],
    },
    {
        routeName: 'raaTools.memberships.index',
        items: [
            {
                title: 'add new membership',
                routeName: 'raaTools.memberships.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.parties.index',
        items: [
            {
                title: 'add new party',
                routeName: 'raaTools.parties.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.applications.index',
        items: [
            {
                title: 'add application',
                routeName: 'raaTools.applications.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.profiles.index',
        items: [
            {
                title: 'add profile',
                routeName: 'raaTools.profiles.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
            {
                title: 'manage templates',
                routeName: 'raaTools.profiles.templates.index',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.profiles.details',
        items: [
            {
                title: 'edit profile',
                routeName: 'raaTools.profiles.edit',
                params: {
                    profileId: '{profileId}',
                },
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.profiles.dataBlocks.edit',
        items: [
            {
                title: 'add new version',
                routeName: 'raaTools.profiles.dataBlocks.clone',
                params: {
                    name: '{name}',
                    version: '{version}',
                },
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.profiles.templates.index',
        items: [
            {
                title: 'add template',
                routeName: 'raaTools.profiles.templates.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.sharedDataKeys.index',
        items: [
            {
                title: 'add data key',
                routeName: 'raaTools.sharedDataKeys.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.relationshipTypes.details',
        items: [
            {
                title: 'add data block',
                routeName: 'raaTools.relationshipTypes.dataBlock.create',
                params: {},
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
    {
        routeName: 'raaTools.relationshipTypes.dataBlock.edit',
        items: [
            {
                title: 'add new version',
                routeName: 'raaTools.relationshipTypes.dataBlock.clone',
                params: {
                    type: '{type}',
                    name: '{name}',
                    version: '{version}',
                },
                allowed: createAppAccessChecker('RAA'),
            },
        ],
    },
];
