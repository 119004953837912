<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template #default="props">
            <div class="form-col">
                <FieldLabel
                    v-if="label || !!$slots['label']"
                    :name="name"
                    :tooltip="tooltip"
                >
                    <slot name="label">
                        {{ label }}
                    </slot>
                </FieldLabel>

                <TextInput
                    v-if="editMode"
                    ref="input"
                    v-bind="$attrs"
                    :value="props.value"
                    :name="props.name"
                    :class="{ error: props.meta.error && props.meta.touched }"
                    :input-class="inputClass"
                    :max-length="maxLength"
                    v-on="{ ...props.events, input: val => props.change(val) }"
                    @clear="clearValue"
                >
                    <template
                        v-if="!!$slots['leading-icon']"
                        #leading-icon
                    >
                        <slot name="leading-icon" />
                    </template>

                    <template
                        v-if="!!$slots['trailing-icon']"
                        #trailing-icon
                    >
                        <slot name="trailing-icon" />
                    </template>
                </TextInput>

                <TextInput
                    v-if="!editMode"
                    v-bind="$attrs"
                    :value="props.value"
                    :input-class="inputClass"
                    read-only
                />

                <FieldHint v-if="hint">
                    {{ hint }}
                </FieldHint>

                <FieldError
                    v-if="!suppressError"
                    :name="name"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import TextInput from '@/components/ui/TextInput';
import FieldLabel from '@/components/form/FieldLabel';
import FieldError from '@/components/form/FieldError';
import FieldHint from '@/components/form/FieldHint';

export default {
    name: 'TextField',

    components: {
        FinalField,
        TextInput,
        FieldLabel,
        FieldError,
        FieldHint,
    },

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: undefined,
        },

        inputClass: {
            type: String,
            default: undefined,
        },

        validate: {
            type: [Function, Array],
            required: false,
            default: undefined,
        },

        editMode: {
            type: Boolean,
            default: true,
        },

        suppressError: {
            type: Boolean,
            required: false,
            default: false,
        },

        hint: {
            type: String,
            required: false,
            default: undefined,
        },

        maxLength: {
            type: Number,
            default: undefined,
        },

        tooltip: {
            type: String,
            default: '',
        },
    },

    emits: ['clear'],

    methods: {
        clearValue() {
            this.$refs.field.fieldState.change('');
            this.$emit('clear');
        },

        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>
