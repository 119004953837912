<template>
    <Loader
        :loading="loading"
        loader-classes="bg-white"
    />
    <Authenticator>
        <template #default="slotParams">
            <div class="py-4 px-8 bg-gray-100 text-gray-700 font-light min-h-screen font-inter relative">
                <top-bar
                    :selectors-disabled="loadInModal"
                    :logout="slotParams.logout"
                />
                <alert />
                <idle :logout="slotParams.logout" />

                <div
                    id="app-view"
                    class="container mx-auto"
                >
                    <router-view v-if="!loadInModal && !missingCustomerContext" />
                    <div v-if="missingCustomerContext">
                        <div
                            class="flex items-center justify-center font-semibold font-inter p-4 py-6 bg-highlight-100 text-highlight-smallText border border-highlight-300"
                        >
                            <span><Icon
                                class="w-6 h-6 mr-2 text-highlight-textLarge"
                                name="Info"
                            /></span>
                            <span>please select a {{ requiredInfo.join(' and a ') }} to continue, then refresh the browser</span>
                        </div>
                    </div>
                </div>

                <router-view
                    v-if="loadInModal && !missingCustomerContext"
                    v-slot="{ Component }"
                >
                    <modal
                        :size="modalSize"
                        :has-actions="hasActions"
                        :current-route-name="currentRouteName"
                    >
                        <component
                            :is="Component"
                            :key="currentRouteName"
                        />
                    </modal>
                </router-view>

                <modal
                    v-if="loadInModal && missingCustomerContext"
                    :size="modalSize"
                    :has-actions="hasActions"
                    :current-route-name="currentRouteName"
                >
                    <div
                        class="flex items-center justify-center font-semibold font-inter p-4 py-6 bg-highlight-100 text-highlight-textLarge border border-highlight-300"
                    >
                        <span><Icon
                            class="w-6 h-6 mr-2"
                            name="Info"
                        /></span>
                        <span>please select a {{ requiredInfo.join(' and a ') }} to continue, then refresh the browser</span>
                    </div>
                </modal>

                <ws-connector />

                <permission-connector />
            </div>
        </template>
        <template #unauthenticated>
            <div>
                <alert />
                <router-view />
            </div>
        </template>
    </Authenticator>
</template>

<script>
import Auth from '@/mixins/Auth';
import AppsPermissionsFetcher from '@/mixins/AppsPermissionsFetcher';
import EventBus from '@/utils/EventBus';
import Icon from '@/components/ui/Icon';
import Idle from '@/components/layout/Idle';
import { mapActions, mapGetters } from 'vuex';
import Alert from './components/layout/alert/Alert';
import TopBar from '@/components/layout/TopBar';
import Modal from '@/components/ui/modals/Modal';
import WsConnector from '@/components/wst/WSConnector';
import Authenticator from '@/components/auth/Authenticator';
import Loader from '@/components/ui/Loader.vue';
import PermissionConnector from '@/components/permission_service/PermissionConnector';
import { provide, ref } from 'vue';

export default {
    components: { PermissionConnector, WsConnector, Authenticator, TopBar, Modal, Alert, Idle, Icon, Loader },

    mixins: [Auth, AppsPermissionsFetcher],

    setup() {
        const loading = ref(false);
        const setLoading = value => {
            loading.value = value;
        };

        provide('loading', { loading, setLoading });
    },

    data: () => {
        return {
            loading: false,
            loadInModal: false,
            hasActions: false,
            actions: [],
            currentRouteName: null,
            modalSize: 'normal',
            modalTitle: '',
            requiredInfo: [],
        };
    },

    computed: {
        ...mapGetters({
            profile: 'auth/profile',
            community: 'auth/community',
            appsPermissions: 'auth/appsPermissions',
            communityAppCodes: 'auth/customerCommunityAppCodes',
        }),

        missingCustomerContext() {
            return this.requiredInfo.length;
        },
    },

    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler: function (newVal) {
                this.loading = !this.$route.name;

                this.requiredInfo = [];

                if (newVal.meta && newVal.meta.requiresCustomer && !this.profile?.customerId) this.requiredInfo.push('customer');

                if (newVal.meta && newVal.meta.requiresCommunity && !this.community) this.requiredInfo.push('community');

                this.loadInModal = newVal.meta && newVal.meta.loadInModal;
                this.currentRouteName = newVal.name;
                this.hasActions = newVal.meta && newVal.meta.actions;
                this.modalSize = newVal.meta.size ?? 'normal';
                this.modalTitle = newVal.meta.title ?? '';
                this.setActiveModalTitle(this.modalTitle);
            },
        },

        appsPermissions: {
            immediate: true,
            deep: true,
            handler: function () {
                this.recalculateSearchableRoutes();
            },
        },

        communityAppCodes: {
            deep: true,
            handler: function () {
                this.recalculateSearchableRoutes();
            },
        },
    },

    created() {
        this.unwatchModalRoute = this.$watch('currentRouteName', routeName => {
            if (routeName) {
                this.refreshModals(routeName);
                this.unwatchModalRoute();
            }
        });
    },

    mounted() {
        this.resetActions();
        this.resetFlash();

        EventBus.on('auth-info-received', () => {});

        this.recalculateSearchableRoutes();
    },

    methods: {
        ...mapActions({
            resetActions: 'actions/resetActions',
            setActiveModalTitle: 'modals/setActiveModalTitle',
            setSearchableRoutes: 'search_routes/loadSearchableRoutes',
            resetFlash: 'flashes/resetFlash',
            refreshModals: 'modals/refreshModals',
        }),

        recalculateSearchableRoutes() {
            this.setSearchableRoutes(
                this.getFlattenRoutes().filter(
                    route =>
                        route.meta?.title &&
                        route.meta?.flowStartsWith &&
                        !route.meta?.hideFromSearch &&
                        (!route.meta?.permissionChecker || route.meta.permissionChecker())
                )
            );
        },

        getFlattenRoutes() {
            const flattenRoutes = [];

            const traverse = routes => {
                if (!routes) {
                    return;
                }

                routes.forEach(route => {
                    if (!route?.meta?.hideFromSearch) {
                        flattenRoutes.push(route);
                    }
                    traverse(route.children);
                });
            };

            traverse(this.$router.options.routes);

            return flattenRoutes;
        },
    },
};
</script>
