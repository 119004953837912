import { computed, ref } from 'vue';
import { isEmpty, isEqual, omit } from 'lodash-es';
import store from '@/store';
import { useAlertStore } from '@/stores/alert';
import AuthDataProvider from '@/api/auth';

const getRefreshedProfile = (newProfile, oldProfile = {}, isQuext) => {
    // TODO: update auth/profile store and refactor logic of the selected profile
    // on init `isQuext` is always false as soon as no active profile set yet.
    if (isQuext) {
        const newProfileData = omit(newProfile, ['customerDeletedAt', 'customerId', 'customerName']);
        const { customerDeletedAt, customerId, customerName, ...oldProfileData } = oldProfile;

        return isEqual(newProfileData, oldProfileData)
            ? null
            : {
                ...newProfileData,
                customerDeletedAt,
                customerId,
                customerName,
            };
    }

    return isEqual(newProfile, oldProfile) ? null : newProfile;
};

// We need to refresh customer user profile info to prevent access ASAP when customer is removed
export const useProfilePolling = () => {
    const REFRESH_INTERVAL = 60000;

    const pollingIntervalId = ref(null);

    const userProfile = computed(() => store.getters['auth/profile']);
    const userProfiles = computed(() => store.getters['auth/profiles']);

    const isQuext = computed(() => store.getters['auth/isQuext']);

    const { notifyError } = useAlertStore();

    const pullProfile = async () => {
        try {
            const profile = await AuthDataProvider.adapter.get('profile');
            const { customerUsers: profiles = [] } = profile;

            if (isEmpty(profiles)) {
                console.error('Your profile is disabled');
                //TODO: should a user be logged out or redirected to ProfileDisabledPage
                return;
            }

            if (!isEqual(userProfiles.value, profiles)) {
                await store.dispatch('auth/setProfiles', profiles);
            }

            const fetchedProfile = profiles.find(p => p.profileId === userProfile.value?.profileId) || profiles[0];

            const refreshedProfile = getRefreshedProfile(fetchedProfile, userProfile.value, isQuext.value);

            if (refreshedProfile) {
                await store.dispatch('auth/setProfile', refreshedProfile);
            }

            return profile;
        } catch (error) {
            notifyError(error);
        }
    };

    const startPolling = async () => {
        clearInterval(pollingIntervalId.value);
        await pullProfile();
        console.trace('start pool profile');
        pollingIntervalId.value = setInterval(pullProfile, REFRESH_INTERVAL);
    };

    const stopPolling = () => {
        clearInterval(pollingIntervalId.value);
    };

    return {
        pollingRequired: !isQuext.value,
        startPolling,
        stopPolling,
        pullProfile,
    };
};
