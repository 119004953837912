<template>
    <div :class="finalCss.tableClass">
        <div
            ref="head"
            :class="finalCss.tableHeaderClass"
        >
            <div
                class="row"
                :class="finalCss.headRowClass"
            >
                <div
                    v-for="(column, index) in columns"
                    :key="index"
                    class="column"
                    :class="[finalCss.headCellClass, column.class, { sortable: column.sortable }]"
                    v-on="column.sortable ? { click: () => $emit('sortClick', column.sortCriteria || column.name) } : {}"
                >
                    <!-- @slot List's column heading [column-head:columnName] -->
                    <slot
                        :name="`column-head:${column.name}`"
                        :column="column"
                    >
                        <template v-if="isComponentField(column.title)">
                            <component :is="column.title" />
                        </template>
                        <template v-else>
                            {{ column.title }}
                        </template>
                        <i
                            v-if="column.sortable"
                            :class="{
                                [finalCss.sortableIcon]: true,
                                [finalCss.ascendingIcon]:
                                    (column.sortCriteria || column.name) === sort.name && sort.dir.toLowerCase() === 'asc',
                                [finalCss.descendingIcon]:
                                    (column.sortCriteria || column.name) === sort.name && sort.dir.toLowerCase() === 'desc',
                            }"
                        />
                    </slot>
                </div>
            </div>
        </div>
        <slot name="add" />
        <transition-group
            ref="list"
            name="list"
            tag="div"
            :class="finalCss.tableBodyClass"
        >
            <div
                v-for="(item, itemIndex) in items || []"
                :key="item[trackBy] || itemIndex"
                :class="getBodyRowClasses(item)"
                @click.stop="rowClick(item)"
            >
                <!-- @slot Custom row -->
                <slot
                    name="row"
                    :index="itemIndex"
                    :item="item"
                />
                <template v-if="!hasCustomRow">
                    <div
                        v-for="column in columns"
                        :key="column.name"
                        class="column"
                        :class="[finalCss.bodyCellClass, column.class]"
                    >
                        <!-- @slot List's column [column:columnName] -->
                        <slot
                            :name="`column:${column.name}`"
                            :index="itemIndex"
                            :item="item"
                            :value="rawColumnValue(item, column.name)"
                        >
                            {{ visibleColumnValue(item, column.name) }}
                        </slot>
                    </div>
                </template>
            </div>
        </transition-group>
        <div
            v-if="items && items.length === 0 && !loading"
            class="flex flex-col items-center justify-center bg-active-100 border border-active-300 text-active-700 py-6"
        >
            <div class="flex items-center justify-center">
                <span
                    class="flex items-center justify-center w-4 h-4 border-2 border-active-700 rounded-full text-xs font-frank font-600 mr-2"
                >!</span>
                <span class="font-frank font-medium text-sm">{{ emptyText }}</span>
            </div>
            <slot name="emptyPlaceholderAddition" />
        </div>
    </div>
</template>

<script>
import { get } from 'lodash-es';
import { inject } from 'vue';

const css = {
    tableClass: 'table table--striped table--highlighted',
    tableBodyClass: 'body',
    tableHeaderClass: 'head sticky top-0 z-10',
    headRowClass: 'bg-white',
    headCellClass: 'border-b border-black',
    bodyRowClass: 'row cursor-pointer',
    bodyCellClass: '',
    ascendingIcon: 'active chevron up',
    descendingIcon: 'active chevron down',
    sortableIcon: 'sort-icon grey sort icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
};

export default {
    name: 'List',
    props: {
        /**
         * Custom CSS styles
         */
        css: {
            type: Object,
            default() {
                return css;
            },
        },

        /**
         * Classes to add to a body row for items that meet condition.
         * Key is a class, value is a function that receives current item as an argument and return boolean.
         *
         * Example:
         * {
         *     'border border-active-500': item => item.isActive === true,
         * }
         */
        conditionalBodyRowClasses: {
            type: Object,
            default: () => ({}),
        },

        /**
         * List columns
         */
        columns: {
            type: Array,
            required: true,
        },

        /**
         * List data
         */
        items: {
            type: Array,
            default: undefined,
        },

        /**
         * Indicates if items are filtered
         */
        hasFilters: {
            type: Boolean,
            default: false,
        },

        /**
         * Resource name used in 'empty' text
         */
        resourceLabel: {
            type: String,
            default: 'items',
        },

        /**
         * Column name that can uniquely identify each row
         */
        trackBy: {
            type: String,
            default: 'id',
        },

        /**
         * Sort data object
         */
        sort: {
            type: Object,
            default: () => ({
                name: '',
                dir: 'asc',
            }),
        },

        /**
         *  Assigns selected row
         */
        selectedRow: {
            type: [String, Function],
            default: undefined,
        },
    },

    emits: [
        /**
         * Emitted on sort
         */
        'sortClick',
        /**
         * Emitted on row click
         */
        'rowClick',
    ],

    setup() {
        const { loading } = inject('loading', { loading: false });
        return { loading };
    },

    computed: {
        emptyText() {
            return this.hasFilters ? 'no results' : `no ${this.resourceLabel} yet`;
        },

        hasCustomRow() {
            return !!this.$slots['row'];
        },

        finalCss() {
            return { ...css, ...this.css };
        },
    },

    methods: {
        rawColumnValue: get,

        visibleColumnValue(item, column) {
            const v = get(item, column);
            return v == null || v === '' || (Array.isArray(v) && v.length === 0) ? '-' : v;
        },

        isComponentField(fieldName) {
            return fieldName instanceof Object;
        },

        rowClick(item) {
            this.$emit('rowClick', item);
        },

        isSelected(item = {}) {
            switch (typeof this.selectedRow) {
                case 'string':
                case 'number':
                    return item[this.trackBy] === this.selectedRow;

                case 'function':
                    return this.selectedRow(item);

                default:
                    return false;
            }
        },

        getBodyRowClasses(item) {
            const classes = [
                this.finalCss.bodyRowClass,
                Object.entries(this.conditionalBodyRowClasses).reduce((acc, [className, conditionFn]) => {
                    acc[className] = conditionFn(item);
                    return acc;
                }, {}),
            ];

            if (this.isSelected(item)) {
                classes.push('row--selected');
            }

            return classes;
        },
    },
};
</script>

<style scoped>
.table {
    @apply text-black font-normal w-full block;
}

.row {
    @apply flex;

    .list-enter-from,
    .list-leave-to {
        @apply bg-active-100;
    }

    .list-enter-active,
    .list-leave-active {
        @apply transition-all duration-500;

        > .column {
            @apply transition-all duration-500;
        }
    }
}

.row--selected {
    @apply border;
    background-color: theme('colors.active.50') !important;
    border-color: theme('colors.active.500') !important;
}

.column {
    background-color: inherit;
}

.head > .row {
    > .column {
        @apply p-2 break-words;
        @apply font-frank font-medium text-xs;

        &.sortable:hover {
            @apply text-active-600;
            cursor: pointer;
        }

        &:first-child {
            @apply pl-4;
        }

        &:last-child {
            @apply pr-6;
        }
    }
}

.table--striped > .body > .row:nth-child(even) {
    @apply bg-graphite-200;
}

.table--highlighted > .body > .row:hover {
    @apply bg-active-100;
}

.body > .row {
    @apply items-stretch;

    & > .column {
        @apply items-center text-sm border-none px-2 py-4 break-words border border-transparent;

        &:first-child {
            @apply pl-4;
        }

        &:last-child {
            @apply pr-6;
        }
    }
}

i.sort::after {
    display: flex;
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' width='10' height='12' viewBox='0 0 10 12' fill='none'><path d='M10 5L5 -4.37114e-07L0 5' fill='rgb(199 199 198)'/><path d='M0 7L5 12L10 7' fill='rgb(199 199 198)'/></svg>");
}

i.chevron.up::after {
    @apply flex w-0 h-0;
    content: '';
    line-height: 0;
    border-left: 0.45em solid transparent;
    border-right: 0.45em solid transparent;
    border-bottom: 0.45em solid theme('colors.active.700');
}

i.chevron.down::after {
    @apply flex w-0 h-0;
    content: '';
    line-height: 0;
    border-left: 0.45em solid transparent;
    border-right: 0.45em solid transparent;
    border-top: 0.45em solid theme('colors.active.700');
}

.sort-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3rem;
    margin-bottom: 0.2rem;
}
</style>
