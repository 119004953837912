const DATA_CONSUMING_SYSTEM_ROUTES = {
    DASHBOARD: {
        name: 'consuming-system.index',
        path: '/consuming-system',
        breadcrumbName: 'Integration Dashboard',
        title: 'Dashboard',
    },
    ADD_SCHEDULE: {
        name: 'consuming-system.schedule.add',
        path: '/consuming-system/schedule/create',
        breadcrumbName: 'Add new',
        title: 'Add new schedule',
    },
    EDIT_SCHEDULE: {
        name: 'consuming-system.edit',
        path: '/consuming-system/schedule/:scheduleId',
        breadcrumbName: 'Edit schedule',
        title: 'Edit schedule',
    },
    VIEW_TASK: {
        name: 'consuming-system.details',
        path: '/consuming-system/task/:jobTransactionId',
        breadcrumbName: 'Task Details',
        title: 'Task Details',
    },
    VIEW_JOB: {
        name: 'consuming-system.job',
        path: '/consuming-system/job/:requestId',
        breadcrumbName: 'Jobs',
        title: 'Jobs',
        props: true,
    },
    VIEW_JOB_BY_STATUS: {
        name: 'consuming-system.status.job',
        path: '/consuming-system/job/status/:status',
        breadcrumbName: 'Jobs',
        title: 'Jobs',
        props: true,
    },
    VIEW_EVENT: {
        name: 'consuming-system.event',
        path: '/consuming-system/event/:jobTransactionId?',
        breadcrumbName: 'Events',
        title: 'Events',
    },
    VIEW_MESSAGE: {
        name: 'consuming-system.message',
        path: '/consuming-system/message/:jobTransactionId?/:messageId?',
        breadcrumbName: 'Messages',
        title: 'Messages',
    },
    VIEW_SCHEDULES: {
        name: 'consuming-system.schedules',
        path: '/consuming-system/schedules',
        breadcrumbName: 'Schedules',
        title: 'Schedules',
    },
};

export default DATA_CONSUMING_SYSTEM_ROUTES;
