<template>
    <div
        v-if="actions.length && !triggerHidden"
        class="fab-wrapper"
    >
        <div
            class="fab"
            :class="{ open: isOpen }"
        >
            <div class="bg" />
            <Icon
                name="dotsVertical"
                class="icon text-white"
                @click.stop="openFab"
            />
        </div>
        <div class="fab-menu">
            <ul class="fab-menu-text">
                <li
                    v-for="(action, index) in actions"
                    :key="index"
                    :class="{ 'fab-menu-item--dynamic': action.dynamic, 'fab-menu-item--disabled': action.disabled }"
                    @click.stop="closeFab"
                >
                    <a
                        v-if="action.showConfirmation"
                        class="cursor-pointer"
                        @click.stop="confirm(action)"
                    >
                        {{ action.title }}
                    </a>
                    <a
                        v-else-if="action.isDownload"
                        class="cursor-pointer"
                        @click.stop="download(action)"
                    >
                        {{ action.title }}
                    </a>
                    <a
                        v-else-if="action.showUploader"
                        class="cursor-pointer"
                        @click.stop="uploader(action)"
                    >
                        {{ action.title }}
                    </a>
                    <a
                        v-else-if="action.isDHPreview"
                        class="cursor-pointer"
                        @click.stop="previewDH()"
                    >
                        {{ action.title }}
                    </a>
                    <a
                        v-else-if="action.isRegular"
                        class="cursor-pointer"
                        @click.stop="regular(action)"
                    >
                        {{ action.title }}
                    </a>
                    <router-link
                        v-else
                        :to="{ name: action.routeName, params: bindRouteParams(action.params) }"
                    >
                        {{ action.title }}
                    </router-link>
                </li>
            </ul>
            <div
                class="close"
                @click.stop="closeFab"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    class="icon icon-tabler icon-tabler-x"
                    viewBox="0 0 24 24"
                >
                    <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                    />
                    <path d="M18 6L6 18M6 6l12 12" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';
import { findRouteByName, isRouteAllowed } from '@/router';
import AuthMixin from '@/components/auth/AuthMixin';
import getEnvVariable from '@/utils/getEnvVariable';
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    mixins: [AuthMixin],

    props: {
        direction: {
            type: String,
            required: false,
            default: 'left',
        },

        routeName: {
            type: String,
            required: true,
            default: '',
        },
    },

    data: () => {
        return {
            isOpen: false,
            triggerHidden: false,
            uid: '_' + Math.random().toString(36).substr(2, 9),
        };
    },

    computed: {
        ...mapGetters({
            getActionsByRouteName: 'actions/getActionsByRouteName',
        }),

        actions() {
            this.closeFab();
            return this.getActionsByRouteName(this.routeName)
                .filter(action => {
                    const routeAllowed = isRouteAllowed(findRouteByName(action.routeName));
                    const actionAllowed = typeof action.allowed === 'undefined' || action.allowed(this.profile);

                    return (
                        actionAllowed &&
                        (action.isDownload || action.showConfirmation || action.isDHPreview || action.showUploader || routeAllowed) &&
                        !action.hideOnFab
                    );
                })
                .sort((a, b) => (a.dynamic | 0) - (b.dynamic | 0));
        },
    },

    created() {
        window.addEventListener('click', this.handleOutsideClickEvent);
    },

    beforeUnmount() {
        window.removeEventListener('click', this.handleOutsideClickEvent);
    },

    methods: {
        openFab() {
            this.isOpen = true;
            const el = document.querySelector('.fab-wrapper');
            var menu = document.querySelector('.fab-menu');
            var dimensions = menu.getBoundingClientRect();
            // set the width/height of the menu for transforms
            var menuWidth = dimensions.width;
            var menuHeight = dimensions.height;

            el?.style.setProperty('--menuWidth', menuWidth + 'px');
            el?.style.setProperty('--menuHeight', menuHeight + 'px');
        },

        closeFab() {
            this.isOpen = false;
            const el = document.querySelector('.fab-wrapper');
            if (!el) return; // set the width/height of the menu for transforms

            el?.style.setProperty('--menuWidth', '2.5rem');
            el?.style.setProperty('--menuHeight', '2.5rem');
        },

        toggleMenu(e) {
            this.isOpen = !this.isOpen;
            e.stopPropagation();
        },

        handleOutsideClickEvent(e) {
            if (this.isOpen) {
                const el = this.$el;
                if (el && !el.contains(e.target)) {
                    this.closeFab();
                }
            }
        },

        bindRouteParams(params) {
            if (typeof params !== 'object') return {};

            const _parameters = {};

            Object.entries(params).forEach(([key, value]) => {
                if (typeof value === 'string') {
                    const matches = value.match(/^{(.*)}$/);
                    if (matches) {
                        _parameters[key] = this.$route.params[matches[1]];
                        return;
                    }
                }

                _parameters[key] = value;
            });

            return _parameters;
        },

        regular(action) {
            EventBus.emit(`${action.id}`, action);
            this.closeFab();
        },

        confirm(action) {
            EventBus.emit('show-modal-confirmation', action);
            this.closeFab();
        },

        uploader(action) {
            EventBus.emit('show-modal-uploader', action);
            this.closeFab();
        },

        download(action) {
            EventBus.emit('show-modal-download', action);
            this.closeFab();
        },

        previewDH() {
            const baseURL = getEnvVariable('VUE_APP_DIGITAL_HUMAN_AGENT_URL');
            if (this.community?.id) window.open(`${baseURL}?community=${this.community?.id}`);
            this.closeFab();
        },
    },
};
</script>

<style scoped>
.drop-menu {
    min-width: 12rem;
}

.drop-menu.right {
    right: 0;
}

.action-wrapper:before {
    content: '';
    position: absolute;
    background: transparent;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 0 0 0 2px theme(colors.active.500);
}

.action-wrapper:after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    top: 0;
    box-shadow: 0 0 0 1px #2c2c2c;
}
</style>

<style>
/* purgecss ignore */
:not(.fab-menu-item--dynamic) + .fab-menu-item--dynamic {
    border-top: 1px solid #ffffff1a;
}

.fab-wrapper {
    --menuWidth: 2.5rem;
    --menuHeight: 2.5rem;
}

.fab-wrapper {
    position: absolute;
    top: 3.125rem;
    right: -1.5rem;
    z-index: 999;
}

.fab {
    display: block;
    border-radius: 50%;
    width: var(--menuWidth);
    height: var(--menuHeight);
    margin: 0 auto;
    position: absolute;
    transform-origin: 100% 0%;
    cursor: pointer;
}

.fab.open {
    right: -2.5rem;
}

.fab.open .bg {
    border-radius: 0;
    width: var(--menuWidth);
    height: var(--menuHeight);
}

.fab.open .icon {
    opacity: 0;
    transition-delay: 0.1s;
}

.fab.open + .fab-menu {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.35s;
}

.fab.open + .fab-menu .close {
    opacity: 1;
}

.fab .bg {
    width: var(--menuWidth);
    height: var(--menuHeight);
    background: theme(colors.active.500);
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 100% 0;
    border-radius: 50%;
    box-shadow:
        0 0 0 1px #262626,
        0 0 0 3px theme(colors.active.500);
    transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: 0.125s;
}

.fab .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.15s;
    transition-delay: 0.5s;
    opacity: 1;
    width: 1.5rem;
    height: 1.5rem;
}

.fab-menu {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    padding: 2rem 1.5rem 1.5rem;
    min-width: 9rem;
    position: absolute;
    top: 0;
    right: -2.5rem;
    transition: 0.2s;
    transition-delay: 0.1s;
    text-transform: lowercase;
}

.fab-menu li {
    padding: 0.375rem 0;
    font-family: 'Inter';
}

.fab-menu a {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 0.875rem;
    white-space: nowrap;
}

.fab-menu-item--disabled a {
    opacity: 0.4;
}

.fab-menu .close {
    position: absolute;
    color: #fff;
    top: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0;
    transition: 0.1s;
    transition-delay: 0.25s;
}

.fab-menu .close svg {
    width: 1.25rem;
}
</style>
