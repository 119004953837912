const IPSV2_PERMISSIONS = {
    CREATE_PARTNER: 'partner_create_ips',
    VIEW_PARTNER: 'partner_view_ips',
    UPDATE_PARTNER: 'partner_update_ips',
    DELETE_PARTNER: 'partner_delete_ips',
    CREATE_PURPOSE: 'purpose_create_ips',
    VIEW_PURPOSE: 'purpose_view_ips',
    UPDATE_PURPOSE: 'purpose_update_ips',
    DELETE_PURPOSE: 'purpose_delete_ips',
    CREATE_PARAMETER: 'parameter_create_ips',
    VIEW_PARAMETER: 'parameter_view_ips',
    UPDATE_PARAMETER: 'parameter_update_ips',
    DELETE_PARAMETER: 'parameter_delete_ips',
    CREATE_PARAMETER_VALUES: 'parameter_values_create_ips',
    VIEW_PARAMETER_VALUES: 'parameter_values_view_ips',
    UPDATE_PARAMETER_VALUES: 'parameter_values_update_ips',
    DELETE_PARAMETER_VALUES: 'parameter_values_delete_ips',
    CREATE_PURPOSE_ASSOCIATION: 'purpose_association_create_ips',
    VIEW_PURPOSE_ASSOCIATION: 'purpose_association_view_ips',
    UPDATE_PURPOSE_ASSOCIATION: 'purpose_association_update_ips',
    DELETE_PURPOSE_ASSOCIATION: 'purpose_association_delete_ips',
    VIEW_COMMUNITY: 'community_view_ips',
    CREATE_COMMUNITY: 'community_create_ips',
    UPDATE_COMMUNITY: 'community_update_ips',
    DELETE_COMMUNITY: 'community_delete_ips',
};

export default IPSV2_PERMISSIONS;
