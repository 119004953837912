const DCS_PERMISSIONS = {
    CREATE_SCHEDULES: 'schedules_create_ips',
    VIEW_SCHEDULES: 'schedules_view_ips',
    UPDATE_SCHEDULES: 'schedules_update_ips',
    DELETE_SCHEDULES: 'schedules_delete_ips',
    EXECUTE_ONE_TIME_SYNC: 'one_time_sync_execute',
    READ_ONE_TIME_SYNC: 'one_time_sync_read',
};

export default DCS_PERMISSIONS;
