import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import EMAILBOT_PERMISSIONS from '@/components/emailbot/common/emailbot_permissions_constants';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        path: '/template/:templateId',
        name: 'template.edit',
        component: () => import('@/views/emailbot/template/TemplateEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit template',
            title: 'edit template',
            hideFromSearch: true,
            flowStartsWith: 'template.index',
            requiresCustomer: true,
            size: 'large',
            permissionChecker: () =>
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE)() ||
                createPermissionChecker(EMAILBOT_PERMISSIONS.EDIT_COMMUNITY_TEMPLATE)(),
        },
    },
    {
        path: '/template/create',
        name: 'template.create',
        component: () => import('@/views/emailbot/template/TemplateCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new template',
            title: 'add new template',
            flowStartsWith: 'emailbot.index',
            hideFromSearch: true,
            requiresCustomer: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.CREATE_COMMUNITY_TEMPLATE),
        },
    },
    {
        path: '/template/preview',
        name: 'template.preview',
        component: () => import('@/views/emailbot/template/TemplatePreview'),
        meta: {
            loadInModal: false,
            flowStartsWith: 'template.index',
            hideFromSearch: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE),
        },
    },
    {
        path: '/template/unsubscribe/:unsubscribeToken',
        name: 'template.unsubscribe',
        component: () => import('@/views/emailbot/template/TemplateUnsubscribe'),
        meta: {
            bareUnauthorized: true,
        },
    },
    {
        path: '/template/view/:templateId',
        name: 'template.view',
        component: () => import('@/views/emailbot/template/TemplatePreview'),
        meta: {
            bareUnauthorized: true,
        },
    },
];
