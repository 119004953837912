import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import Axios from 'axios';
import { omitBy, isNil } from 'lodash-es';
import queryString from 'query-string';
import vuexStore from '@/store';
import { useAlertStore } from '@/stores/alert';

const httpClient = baseURL => async request => {
    const { notifyError } = useAlertStore();

    try {
        let authHeaders = {};
        if (!request.noAuth) {
            const { tokens } = await fetchAuthSession();

            authHeaders = {
                Authorization: `Bearer ${tokens.idToken}`,
                // TODO: rename it to "X-Quext-Profile-Id"
                'X-Customer-User-Id': vuexStore.getters['auth/profile']?.profileId,
                'X-Quext-Customer': vuexStore.getters['auth/profile']?.customerId,
            };
        }

        return Axios.request({
            ...request,
            baseURL,
            paramsSerializer: params => queryString.stringify(params),
            headers: omitBy(
                {
                    ...authHeaders,
                    ...request.headers,
                },
                isNil
            ),
        }).catch(error => {
            const response = error?.response;
            // Proxy response from RAA contains a string with a detailed error message in 'data' prop.
            const proxyResponse = typeof response?.data === 'string';
            const message = (proxyResponse && response?.data) || response?.data?.message || error.message;
            // Temporally commented
            // if (response?.status === 401) {
            //   await signOut();
            // }

            return Promise.reject({
                message,
                ...response,
            });
        });
    } catch (e) {
        notifyError(e);
        await signOut();
    }
};

export default httpClient;
